import React, {Component} from 'react'
import './treediagram.scss'
import {Left} from './left'
import {Right} from './right'

export class Tree extends Component {
    constructor() {
        super()
        this.setState({
            initialized: false
        })
    }
    componentWillMount() {
    }

    render() {
        return (
            <div>
                {this.props.data.knockoutchart && <div className="tree-wrapper">
                        <div className="head">
                            <div className={''}>Nyolcaddöntő</div>
                            <div className={''}>Negyeddöntő</div>
                            <div className={''}>Elődöntő</div>
                            <div className={''}>Elődöntő</div>
                            <div className={''}>Negyeddöntő</div>
                            <div className={''}>Nyolcaddöntő</div>
                        </div>
                        <div className="left">
                            <Left data={this.props.data.knockoutchart}/>
                        </div>
                        <div className="right">
                            <Right data={this.props.data.knockoutchart}/>
                        </div>
                        <div className="texts">
                            {/* @todo: extract to a component */}
                            {this.props.data.knockoutchart[16] && <div className="winner-gold">
                                <div className="title">Győztes</div>
                                <div className="date">{this.props.data.knockoutchart[16] && this.props.data.knockoutchart[16].date}</div>
                                <div className="teams">
                                    <div className={"team" + (!!this.props.data.knockoutchart[16].result1 && !!this.props.data.knockoutchart[16].result2 &&  this.props.data.knockoutchart[16].result1 > this.props.data.knockoutchart[16].result2 ? ' winner': ' looser')}>
                                        <div className="name">{this.props.data.knockoutchart[16] && this.props.data.knockoutchart[16].team1_text}
                                            {this.props.data.knockoutchart[16] && <div className={'result'}>{this.props.data.knockoutchart[16].result1}</div> }</div>
                                        <div className="logo"><img src={this.props.data.knockoutchart[16] && 'https://' + this.props.data.knockoutchart[16].t1logo} alt=""/></div>
                                    </div>
                                    <div className={"team" + (!!this.props.data.knockoutchart[16].result1 && !!this.props.data.knockoutchart[16].result2 &&   this.props.data.knockoutchart[16].result1 < this.props.data.knockoutchart[16].result2 ? ' winner': ' looser')}>
                                        <div className="name">{this.props.data.knockoutchart[16] && this.props.data.knockoutchart[16].team2_text}
                                            {this.props.data.knockoutchart[16] && <div className={'result'}>{this.props.data.knockoutchart[16].result2}</div> }
                                        </div>
                                        <div className="logo"><img src={this.props.data.knockoutchart[16] && 'https://' + this.props.data.knockoutchart[16].t2logo} alt=""/></div>
                                    </div>
                                </div>
                            </div>}
                            {this.props.data.knockoutchart[16] && <div className="winner-bronze">
                                <div className="title">Bronzmeccs</div>
                                <div className="date">{this.props.data.knockoutchart[15] ? this.props.data.knockoutchart[15].date : ' '}</div>
                                <div className="teams">
                                    <div className={"team" + (!!this.props.data.knockoutchart[16].result1 && !!this.props.data.knockoutchart[16].result2 &&  this.props.data.knockoutchart[16].result1 > this.props.data.knockoutchart[16].result2 ? ' winner': ' looser')}>
                                        <div className="name">{this.props.data.knockoutchart[15] ? this.props.data.knockoutchart[15].team1_text : ' '}{this.props.data.knockoutchart[15] ? <div className={'result'}>{this.props.data.knockoutchart[15].result1}</div> : ' '}</div>
                                        <div className="logo"><img src={this.props.data.knockoutchart[15] ? 'https://' + this.props.data.knockoutchart[15].t1logo : ' '} alt=""/></div>
                                    </div>
                                    <div className={"team" + (!!this.props.data.knockoutchart[16].result1 && !!this.props.data.knockoutchart[16].result2 &&   this.props.data.knockoutchart[16].result1 < this.props.data.knockoutchart[16].result2 ? ' winner': ' looser')}>
                                        <div className="name">{this.props.data.knockoutchart[15] ? this.props.data.knockoutchart[15].team2_text : ''}{this.props.data.knockoutchart[15] ? <div className={'result'}>{this.props.data.knockoutchart[15].result2}</div> : ' '}</div>
                                        <div className="logo"><img src={this.props.data.knockoutchart[15] ?  'https://' + this.props.data.knockoutchart[15].t2logo : ' '} alt=""/></div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>}
                {this.props.data.text && <div className="info">{this.props.data.text}</div>}
            </div>
        )
    }
}
