import React, {Component} from 'react';
import {MenuService, NavigationService} from "../../../actions";
import {HungarianTournamentMatches, HungarianTournamentTree} from "./";
import '../championship.scss';


export class HungarianTournament extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollbar: false,
            activeTab: 'matches',
            animationClass: '',
            tabs: [
                {
                    name: 'matches',
                    translate: 'Magya Kupa meccsnaptár'
                },
                {
                    name: 'three',
                    translate: 'Magya Kupa ágrajz'
                },
            ]
        };
    }

    componentDidMount() {
        this.sub = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(() => {
                NavigationService.readyToNavidate(true);
            }, 1000)
        })
    }

    componentWillUnmount() {
        this.sub()
    }

    navigate(tabName) {
        this.setState({
            animationClass: 'out'
        })
        setTimeout(() => {
            this.setState({
                activeTab: tabName,
                animationClass: 'in'
            })

            setTimeout(() => {
                this.setState({
                    animationClass: ''
                })
            }, 300)
        }, 300)
    }

    renderTab() {
        if (this.state.activeTab === 'tabel') {
        }

        switch (this.state.activeTab) {
            case 'matches':
                return <HungarianTournamentMatches/>;
            case 'three':
                return <HungarianTournamentTree/>;
            default:
                return false

        }
    }

    render() {
        return (
            <div className={'bl' + (this.state.animateOut ? ' out' : '')}>
                <div className="back-button" onClick={() => MenuService.setMenuLocation('/championship')}>
                    vissza
                </div>
                <div className="team-menu">
                    {this.state.tabs.map( (tabName, key) => (
                        <div key={key} className="menu-button-wrapper">
                            <div className={"menu-button" + (this.state.activeTab === tabName.name ? ' active': '')} onClick={() => this.navigate(tabName.name)}>
                                {tabName.translate}
                            </div>
                        </div>
                    ) )}
                </div>
                <div className={"tab-container " + this.state.animationClass}>
                    {this.renderTab()}
                </div>
                <div className="telekom-logo">
                    <img src="/images/telekom_logo.svg" alt=""/>
                </div>
            </div>
        )
    }
}
