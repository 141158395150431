import React, {Component} from 'react';
import {MatchCalendar} from '../../../component/match-calendar'
import {MenuService, NavigationService} from "../../../actions";
import '../championship.scss';
import {api} from "../../../common";

export class HungarianTournamentMatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollbar: false,
            activeTab: 'NB1Tabel tabella'
        };


        api('matches/mb', data => {
            this.data = data;
            let date = new Date().getTime();
            let activeKeyFounded = false;
            let activeKey = null;
            this.data.map((row, key) => {
                let matchDate = new Date(row.date).getTime()
                if(!activeKeyFounded) {
                    activeKey = key
                }
                if (date < matchDate) {
                    activeKeyFounded = true
                }
                return false;
            });
            if (!activeKey) {
                activeKey = 0
            }
            this.setState({
                initialized: true,
                activeKey: activeKey
            })
        })
    }

    componentDidMount() {
        this.sub = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(() => {
                NavigationService.readyToNavidate(true);
            }, 1000)
        })
    }

    componentWillUnmount() {
        this.sub()
    }

    render() {
        return (
            <div className={'matches-bl' + (this.state.animateOut ? ' out' : '')}>
                {this.state.initialized && <MatchCalendar activeKey={this.state.activeKey} data={this.data} scrollbar={true}/>}
            </div>
        )
    }
}
