import React, {Component} from 'react';
import {Background} from './component'
import Menu from './component/menu/menu'
import Home from './screens/home';
import {Goals} from './screens/goals';
import {Championship, BL, NB1, HungarianTournament} from './screens/championship';
import {Games, Quiz} from './screens/games';
import {Cups} from './screens/cups';
import {Telekom} from './screens/telekom';
import Players from './screens/players';
import './App.scss';
import {BrowserRouter as Router, Route} from "react-router-dom";
import {Team} from "./screens/team";
import {Positions} from "./screens/games/positions";
import {Memory} from "./screens/games/memory";

try {

    const electron = window.require('electron');
    const ipcRenderer  = electron.ipcRenderer;
    ipcRenderer.on('hwid-recived', (event, arg) => {
        window.hwid = arg;
        console.log(window.hwid)
    });
    ipcRenderer.send('hwid-get');
} catch (e) {
    window.hwid = 'browser';
}

class App extends Component {
    constructor() {
        super();
        this.tokenIsValid = new URL(window.location).searchParams.get('token') === 'GMw1srSZ';
        if (!this.tokenIsValid)
            window.location = 'https://mostszurkolunkveszprem.hu';
        this.state = {
            online: window.navigator.onLine
        };
    }
    componentWillMount() {
        window.addEventListener('online',  () =>this.setState({
            online: true
        }))
        window.addEventListener('offline', () => this.setState({
            online: false
        }))
    }

    render() {
        return (
            <Router>
                {this.tokenIsValid && <Background phase="home">
                        {!this.state.online &&
                        <div className="show-network-state">
                            <div className="icon">
                                <svg width="59" height="59" viewBox="0 0 59 59" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M24.1053 12.4H34.9532L33.1293 33.76H25.8813L24.1053 12.4ZM29.5293 46.432C27.9293 46.432 26.6173 45.952 25.5933 44.992C24.5693 44 24.0573 42.8 24.0573 41.392C24.0573 39.952 24.5693 38.768 25.5933 37.84C26.6173 36.912 27.9293 36.448 29.5293 36.448C31.1293 36.448 32.4253 36.912 33.4173 37.84C34.4413 38.768 34.9532 39.952 34.9532 41.392C34.9532 42.8 34.4413 44 33.4173 44.992C32.3933 45.952 31.0973 46.432 29.5293 46.432Z"
                                        fill="#FF0000"/>
                                    <circle cx="29.5" cy="29.5" r="27.5" stroke="#FF0000" strokeWidth="4"/>
                                </svg>
                            </div>
                            <p><span className={"red"}>NINCS</span> INTERNETKAPCSOLAT! <br/>
                                <span className="small">Előfordulhatnak hiányos képernyők és tartalmak.</span></p>
                            <svg className={'svg-bg'} width="744" height="307" viewBox="0 0 744 307" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0" maskRype="alpha" maskUnits="userSpaceOnUse" x="-462" y="0" width="1206"
                                      height="1251">
                                    <g>
                                        <path
                                            d="M-460.986 829.504C-461.545 830.461 -461.219 831.69 -460.259 832.245L-97.9162 1041.44C-97.6131 1041.62 -97.2693 1041.71 -96.9193 1041.71L382.611 1042.46C383.327 1042.46 383.989 1042.08 384.347 1041.46L623.148 627.846C623.506 627.226 623.505 626.462 623.146 625.843L383.04 211.466C382.683 210.85 382.025 210.47 381.313 210.469L-97.8192 209.597C-98.5315 209.596 -99.1907 209.974 -99.5499 210.589L-460.986 829.504Z"
                                            fill="#5F5F5F"/>
                                    </g>
                                </mask>
                                <g mask="url(#mask0)">
                                    <g style={{
                                        mixBlendMode: 'multiply'
                                    }}>
                                        <rect y="210" width="472" height="97" fill="#000000"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        }
                        <Menu/>
                        <div>
                            {/* render routing */}
                            <Route path="/" exact component={Home}/>
                            <Route path="/players" exact component={Players}/>
                            <Route path="/team" exact component={Team}/>
                            <Route path="/championship/nb1" exact component={NB1}/>
                            <Route path="/championship/bl" component={BL}/>
                            <Route path="/championship/hungarian-tournament" component={HungarianTournament}/>
                            <Route path="/championship" exact component={Championship}/>
                            <Route path="/games" exact component={Games}/>
                            <Route path="/games/quiz" exact component={Quiz}/>
                            <Route path="/games/positions" exact component={Positions}/>
                            <Route path="/games/memory" exact component={Memory}/>
                            <Route path="/goals" exact component={Goals}/>
                            <Route path="/cups" exact component={Cups}/>
                            <Route path="/telekom" exact component={Telekom}/>
                        </div>
                    </Background>}
            </Router>
        );
    }
}
export default App;
