import React, {Component} from 'react';
import {DrawTeamTable} from '../../../component/tabella'
import {MenuService, NavigationService} from "../../../actions";
import {api} from "../../../common";
import '../championship.scss';

export class NB1Tabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollbar: false,
            activeTab: 'NB1Tabel tabella'
        };

        this.nb1 = Array(16);
        this.nb1.fill({
            team: '????',
            playOff: '?',
            playedMatch: '?',
            wins: '?',
            draw: '?',
            loose: '?',
            goalRate: '?',
            pointsRate: '?',
            form: '?',
        });

        this.data = Array(1);
        this.data.fill({
            head: {},
            body: this.nb1
        });
    }

    componentDidMount() {
        this.sub = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(() => {
                NavigationService.readyToNavidate(true);
            }, 600)
        });

        api('leaugetables/nb1', response => {
                this.setState({
                    response: response
                })
            }
        )
    }

    componentWillUnmount() {
        this.sub()
    }

    render() {
        return (
            <div className={'tabella-nb1' + (this.state.animateOut ? ' out' : '')}>
                {this.state.response &&
                    <DrawTeamTable
                        data={this.state.response}
                    />
                }
            </div>
        )
    }
}
