import React, {Component} from 'react';
import Swiper from '@eredessil/react-id-swiper';
import "@eredessil/react-id-swiper/src/styles/scss/swiper.scss";
import "./players.scss"
import {MenuService, NavigationService} from "../actions";
import {PlayerDetails} from './player-details';
import {api} from './../common';

const tabs = [
    {
        type: 'stats',
        name: 'Statisztika',
        content: {}
    },
    {
        name: 'Érdekességek',
        type: 'infos',
        content: {}
    },
    {
        name: 'Tenyérméret teszt',
        type: 'hand-size'
    },
    {
        name: 'Eddigi klubok',
        type: 'history',
    }
];

export default class Players extends Component {
    constructor(props) {
        super(props);
        this.filters = {
            left: [
                'Teljes csapat',
                'Balszélső',
                'Ballövő',
                'Irányító',
                'Beálló',
                'Jobblövő',
                'Jobbszélső',
                'Balszélső'
            ],
            right: [
                'Stáb',
                'Szurkolók'
            ]
        };
        this.state = {
            playersSource: [],
            details: false,
            history: [],
            selectPlayerVisible: true,
            selectDetailsVisible: false,
            activeFilter: 'Teljes csapat',
            legendary: false,
            preventTransition: false
        };
        api('players', response => {
            console.log(response)
            this.setState({
                    players: response.players,
                    playersSource: response.players,
                    filters: response.filters
                },

                // todo: hack

            );
            setTimeout(() => {
                // todo: hack 02
                this.setState({
                    players: response.players,
                    playersSource: response.players,
                    filters: response.filters

                })
            })
        })

        this.showDetails = this.showDetails.bind(this)
    }

    showDetails(id) {
        this.setState({
            preventTransition: true
        })
        api('player/' + id, (player) => {
            let _player = player[0];
            if(_player && Object.keys(_player).length) {
                this.setState({
                    player: _player,
                    selectPlayerVisible: false,
                    selectDetailsVisible: true,
                    legendary: !!parseInt(_player.legendary),
                })
            }
        });
    }

    navigate() {
        if (this.state.selectDetailsVisible) {
            this.setState({
                selectDetailsVisible: false,
                selectPlayerVisible: true,
                legendary: false
            });
            setTimeout(() => {
                this.setState({
                    preventTransition: false
                })
            }, 300)

        } else {
            MenuService.setMenuLocation('/');
            NavigationService.readyToNavidate(true)
        }
    }

    componentDidMount() {
        //this.swiper.goTo(1);
        this.subscribe = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(() => {
                NavigationService.readyToNavidate(true);
            }, 300)
        })
    }

    componentWillUnmount() {
        this.subscribe();
    }

    filterSlider(param) {
        param !== 'Teljes csapat'
            ? this.setState({
                players: this.state.playersSource.filter(item => {
                    return item.name === param
                }),
                activeFilter: param
            })
            : this.setState({players: this.state.playersSource, activeFilter: param});
    }

    backButtonHandler() {
        if (this.state.selectDetailsVisible) {
            this.setState({
                selectDetailsVisible: false,
                preventTransition: false
            })
            setTimeout(() => {
                this.setState({
                    selectPlayerVisible: true
                }, )
            }, 300)
        } else {
            MenuService.setMenuLocation('/')
        }
    }

    render() {
        return (
            <div
                className={this.state.animateOut ? 'animateOut' : null + ' ' + (this.state.preventTransition ? 'prevent-transition' : 'prevent-fix')}
                style={{
                    position: 'relative',
                    zIndex: '5'
                }}>
                <div className="back-button" onClick={() => this.backButtonHandler()}>
                    Vissza
                </div>
                {this.state.players && (
                    <div className={'players' + (this.state.selectPlayerVisible ? ' isVisible' : '')}>
                        <div className="filter-slider">
                            <div className="left-menu">
                                <div
                                    className={"filter-button"}
                                    onClick={() => {
                                        this.filterSlider('Teljes csapat')
                                    }}>
                                    Teljes csapat
                                </div>
                                {
                                    this.state.filters && this.state.filters.map((filterName, key) =>
                                        <div key={key}
                                             className={"filter-button" + (this.state.activeFilter === filterName.position ? ' active' : '')}
                                             onClick={() => {

                                                 this.filterSlider(filterName.position)
                                             }}>
                                            {filterName.position}
                                        </div>
                                    )}
                            </div>
                            <div className="right-menu">

                            </div>
                        </div>
                        {console.log(this.state.preventTransition)}
                        <PlayerSlider players={this.state.players} handler={id => this.showDetails(id)}/>
                    </div>
                )}
                {this.state.selectPlayerVisible ||
                <div className={'player-details-wrapper' + (this.state.selectDetailsVisible ? ' isVisible' : '')}>
                    <PlayerDetails legendary={this.state.legendary} tabs={tabs} player={this.state.player}/>
                </div>
                }
            </div>
        )
    }
}
const params = {
    slidesPerView: 3,
    centeredSlides: true,
    rebuildOnUpdate: true,
    activeSlideKey: 1,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    }
};

class PlayerSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSlideKey: '1',
            preventTransition: false
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps);
    }

    render() {
        return (
            <div>
                <Swiper {...params} activeSlideKey={this.state.activeSlideKey}>
                    {this.props.players.length && this.props.players.map((player, index) => {
                        return <div key={index} className={''}>
                            <div className="player-small">
                                <h3>{player.last_name} {player.first_name.substr(0, 1)}. </h3>
                                <p>{player.name}</p>
                                <div className="image" style={{
                                    backgroundImage: 'url(https://' + player.image + ')'
                                }}>
                                    <div className="details-button"  onClick={() => {
                                        this.props.handler(player.id);
                                        this.setState({
                                            activeSlideKey: index + ''
                                        })
                                    }}>
                                        Tovább
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </Swiper>
            </div>
        )
    }
}
