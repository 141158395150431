import React, {Component} from "react";
import Swiper from "@eredessil/react-id-swiper";
import "@eredessil/react-id-swiper/src/styles/scss/swiper.scss";
import {MenuService, NavigationService} from "../../actions";
import './team.scss'
import {api} from './../../common';

export class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            history: []
        }
    }


    componentWillMount() {
        api('about', response => {
            this.setState({
                history: response
            })
        });

        this.sub = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(function () {
                NavigationService.readyToNavidate(true);
            }, 300)
        })
    }

    componentWillUnmount() {
        this.sub();
    }


    render() {
        const params = {
            slidesPerView: 3,
            centeredSlides: true,
            rebuildOnUpdate: true,
            loopMode: true,
            activeSlideKey: '1',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            onInit: swiper => {
                console.log(swiper)
            }
        };
        return (
            <div className={'team' + (this.state.animateOut ? ' out' : '')}>
                <div className="title">A csapat története</div>
                <Swiper {...params}>
                    {this.state.history && this.state.history.map((year, key) => (
                        <div key={key}>
                            <div className="image">
                                    <img src={'https://' + year.image} alt=""/>
                            </div>
                            <div className="subtitle">
                                {year.title}
                            </div>
                            <div className="text">{year.body}</div>
                            <div className="timeline">
                                <div className="pin"/>
                                <div className="date">
                                    {year.year}
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </Swiper>

                <div className="telekom-logo">
                    <img src="/images/telekom_logo.svg" alt=""/>

                </div>
                <div className="back-button" onClick={() => MenuService.setMenuLocation('/')}>
                    Vissza
                </div>
            </div>

        )
    }
}
