import React, {Component} from 'react'
//import {api} from './../../common'
import './memory.scss'
import {MenuService, NavigationService} from "../../../actions";
// import {BottomAngle} from "../../../component/bottom-angle";
import CardDada from './cardData';
import Swiper from "@eredessil/react-id-swiper";
import "@eredessil/react-id-swiper/src/styles/scss/swiper.scss";
import {postApi} from "../../../common";

//import {postApi} from "../../../common";

export class Memory extends Component {

    static s3() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(36)
            .substring(0, 3)
            .toUpperCase()
    }

    constructor(props) {
        super(props);
        this.initialState = {
            stage: 'intro',
            openedIdOne: null,
            openedIdTwo: null,
            preventClick: false,
            level: 1,
            progress: 0,
            timer: 90,
            showOverlay: false,
            showChooseScreen: false,
            cards: null,
            gameDetails: false
        };
        this.state = this.initialState;
    }

    static getActivationCode() {
        return Memory.s3() + Memory.s3();
    }

    componentWillMount() {
        this.sub = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(function () {
                NavigationService.readyToNavidate(true);
            }, 300)
        })
    }

    componentWillUnmount() {

        this.sub();
    }
    startTimer() {
        this.interval = setInterval(() => {
            if (this.state.timer === 0) {
                this.finishGame();
            }
            this.setState({
                timer: this.state.timer - 1
            })
        }, 1000)
    }

    turnCard(id, marchedId, matched) {
        if (!matched && id !== this.state.openedIdOne && !this.state.preventClick) {
            if (this.state.openedIdOne === null) {
                this.setState({
                    openedIdOne: id,
                    currentPair: marchedId
                })
            } else {
                this.setState({
                    openedIdTwo: id,
                    preventClick: true
                });
                if (this.state.currentPair === marchedId) {
                    let nextState = [...this.state.cards];
                    nextState[this.state.openedIdOne].matched = true;
                    nextState[id].matched = true;
                    this.setState({
                        cards: nextState,
                    });

                    this.checkProgress();
                }
                setTimeout(() => {
                    this.setState({
                        openedIdOne: null,
                        openedIdTwo: null,
                        currentPair: null,
                    });
                    setTimeout(() => {
                        this.setState({
                            preventClick: false
                        })

                    })
                }, 500)
            }
        }
    }

    checkProgress() {
        return this.state.cards.filter(card => card.matched).length === this.state.cards.length
            ? this.finishGame()
            : false;
    }

    startGame(level) {
        let cards = CardDada.getCardList(level * 5);
        console.log(cards);
        this.setState({
            hideOverlay: true,
            screenAnim: 'chang-stage',
            level: level,
            cards: cards,
            openedIdOne: null,
            openedIdTwo: null,
            preventClick: false,
            progress: 0,
            timer: this.initialState.timer
        });
        setTimeout(() => {
            this.setState({
                stage: 'game',
                screenAnim: false,
                showOverlay: false,
                showChooseScreen: false
            });
            setTimeout(() => {
                this.startTimer()
            }, 3700)
        }, 300)
    }

    finishGame() {
        clearInterval(this.interval);
        this.setState({
            timer: 0,
            preventClick: false,
        });

        this.state.level === 5 && this.state.cards.filter(card => card.matched).length === this.state.cards.length
            ? this.showActivationCode()
            : setTimeout(() => {
                if (this.state.progress !== this.state.cards.length) {
                    this.setState({
                        showOverlay: true,
                        showResultOverlay: true
                    })
                }
                this.setState({})
            }, 300)
    }

    showActivationCode() {
        this.setState({screenAnim: 'chang-stage'});
        let code = Memory.getActivationCode();
        setTimeout(() => {
            postApi(() => {
                    this.setState({
                        stage: 'result',
                        screenAnim: '',
                        activationCode: code
                    })
                }, {
                    "kiosk_id": window.hwid.toString(),
                    "date": new Date().getTime(),
                    "code": code
                }, () => {
                    this.setState({
                        stage: 'result',
                        screenAnim: '',
                        activationCode: 'FN87WD'.toUpperCase()
                    })
                }
            )
        }, 600)
    }

    chooseDifficulty() {
        this.setState({
            showOverlay: true,
            showChooseScreen: true,
            showResultOverlay: false,
        })
    }

    static postData = [
        {
            CODE: 'ASDF',
            DATE: '1234567489325625',
            KIOSK_ID: window.hwid
        }
    ];

    render() {

        const params = {
            direction: 'vertical',
            slidesPerView: 'auto',
            freeMode: true,
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            mousewheel: true,
        };
        return (
            <div className={"memory" + (this.state.animateOut ? ' out' : '') + ' ' + this.state.screenAnim} style={{
                backgroundImage: this.state.showOverlay ? "url('/images/home-bg-v2.png')" : null
            }}>
                {/* back button */}
                <div className="back-button" onClick={() => MenuService.setMenuLocation('/games/')}>
                    Vissza
                </div>
                {this.state.stage === 'intro' && <div className="intro-screen">
                    <div className="intro-text">
                        <div className="title">
                            Memória játék
                        </div>
                        <div className="text">
                            <strong>
                                Játssz velünk memóriajátékot!
                            </strong><br/>
                            <br/>
                            Válaszd ki a számodra megfelelő szintet, forgasd fel a
                            kártyákat és <br/>találd meg az összeillő játékosfotókat!
                            <br/>
                            <br/>
                            <strong>
                                Hajrá! Sok sikert!
                            </strong>
                        </div>
                    </div>
                    <div className="image-container-left">
                        <img className={'first'} src="/images/games/memory/intro/small-player-1.png" alt=""/>
                        <img className={'plus'} src="/images/games/memory/intro/plus.png" alt=""/>
                        <img className={'last'} src="/images/games/memory/intro/small-player-2.png" alt=""/>
                    </div>
                    <div className="image-container-right">
                        <img className={'first'} src="/images/games/memory/intro/player-1.png" alt=""/>
                        <img className={'plus'} src="/images/games/memory/intro/plus.png" alt=""/>
                        <img className={'last'} src="/images/games/memory/intro/player-2.png" alt=""/>
                    </div>
                    <div className="start-the-game" onClick={() => this.chooseDifficulty()}>
                        Játszom
                    </div>
                </div>}
                {this.state.stage === 'game' && <div className="card-screen">
                    <div className={this.state.level ? 'level-' + this.state.level : null}>
                        <div className={"timer" + (this.state.timer <= 10 && this.state.timer > 0 ? this.state.timer % 2 === 0 ? ' tick' : ' tack' : '')}>
                            {Math.floor(this.state.timer / 60) > 0 ? Math.floor(this.state.timer / 60) + ':' : ''}{this.state.timer % 60 > 0 ? this.state.timer % 60 < 10 ? '0' + this.state.timer % 60 : this.state.timer % 60 : '00'}
                        </div>
                        <div className="title">Forgasd fel a kártyákat és találd meg az <strong>
                            {this.state.level * 5} összeillő párt!
                        </strong></div>
                        <div className="cards">
                            {this.state.cards.map((card, id) =>
                                <div key={id}
                                     className={'card' + (this.state.openedIdOne === id || this.state.openedIdTwo === id ? ' open' : '') + (card.matched ? ' founded' : '')}
                                     onClick={() => this.turnCard(id, card.pairId, card.matched)}>
                                    <div className="second-wrapper">
                                        <div className="inner-wrapper">
                                            <div className="front-face">
                                                <img src="/images/games/memory/cards/level-1.svg" alt=""/>
                                            </div>
                                            <div className="back-face">
                                                <div className="image-wrapper">
                                                    <img src={'https://telekom-veszprem-kiosk.webdream.hu' + card.image} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="telekom-logo">
                        <img src="/images/telekom_logo.svg" alt=""/>
                    </div>
                </div>}
                {this.state.stage === 'result' && <div className="result-screen">
                    <div className="images">
                        <img src="/images/games/quiz/left-player.png" alt="" className="left"/>
                        <img src="/images/games/quiz/right-player.png" alt="" className="right"/>
                    </div>
                    <div className="card-container">
                        <div className="close">

                        </div>
                        <div className="title">
                            Gratulálunk!
                        </div>
                        <div className="subtitle">
                            Megnyerted a játékosok által dedikált
                            szurkolói mezek egyikét!
                        </div>
                        <div className="text">
                            Nyereményedhez az alábbi <strong>kuponkód szükséges</strong>.
                            Írd fel, vagy fotózd le a kódot és küldd el kollégánknak
                            az alábbi email címre: <strong>info@mostszurkolunkveszprem.hu</strong>
                        </div>

                        <div className="code-box">{this.state.activationCode}</div>
                        <div className="text"> Ha megvan a kód, mindenképpen <strong>
                            zárd be az ablakot!
                        </strong></div>
                        <div className="details" onClick={() => {
                            this.setState({gameDetails: true})
                        }}>
                            Játékszabályzat
                        </div>

                    </div>
                    <div className={"details-view" + (this.state.gameDetails ? ' visible' : '')}>
                        <div className="details-overlay" onClick={() => {
                            this.setState({gameDetails: false})
                        }}>
                            <div className="details-overlay-card">
                                <div className="close" onClick={() => {
                                    this.setState({gameDetails: false})
                                }}>
                                    <svg width="43" height="43" viewBox="0 0 43 43" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect x="12.0215" y="9.19238" width="30" height="4" rx="2"
                                              transform="rotate(45 12.0215 9.19238)" fill="black"/>
                                        <rect x="9.19336" y="30.4058" width="30" height="4" rx="2"
                                              transform="rotate(-45 9.19336 30.4058)" fill="black"/>
                                    </svg>
                                </div>
                                <Swiper {...params}>
                                    <div className="title">
                                        Játék szabályzat
                                    </div>
                                    <div className="text">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam
                                        aspernatur assumenda consectetur cumque debitis delectus dolorem earum
                                        exercitationem facere facilis fugit harum, iste iusto libero nam natus, odio
                                        praesentium qui quod quos repellendus reprehenderit, sapiente similique vitae
                                        voluptas voluptate. Beatae culpa dicta fuga illo itaque nam provident totam
                                        unde, ut? Autem delectus ducimus eius exercitationem explicabo numquam placeat
                                        quos temporibus. Autem beatae deserunt dicta dolores dolorum expedita natus
                                        recusandae vel voluptatibus. Earum et nisi possimus unde ut? Dignissimos ea nam
                                        pariatur? Architecto commodi consequuntur, cupiditate deleniti deserunt ex id
                                        ipsam, nesciunt numquam, odit perspiciatis repellat temporibus voluptate?
                                        Commodi cupiditate delectus, earum libero minus nesciunt officiis porro quia
                                        soluta voluptas. Accusamus illum labore maiores natus perferendis placeat
                                        quibusdam voluptate! Alias architecto consequatur cupiditate dolores, eligendi
                                        eum ex exercitationem fugiat minus nihil nulla porro quae quia quibusdam, quod
                                        quos reiciendis. Atque commodi facilis fugit illum labore, nihil numquam
                                        obcaecati odio officia quos sed sequi tenetur unde. Adipisci alias amet
                                        consequatur cumque deserunt earum, eligendi error ex fugit id illo, illum ipsa
                                        laboriosam magnam nisi numquam officia quaerat quam quas quod saepe sed sint
                                        vitae! Dicta ipsa laboriosam minima natus obcaecati saepe soluta veritatis!
                                        Adipisci assumenda, dolores enim illo inventore maxime nam qui repudiandae
                                        veniam voluptatum. Aspernatur cupiditate dicta dolorum, eveniet molestiae natus
                                        non nulla quo suscipit voluptatem! Accusamus amet atque doloremque illo incidunt
                                        molestiae quo reiciendis repellat velit voluptatibus! Alias debitis doloribus
                                        eum excepturi nostrum rerum sint voluptatem. Eum ex harum quae sit? Atque
                                        commodi consectetur ea id illo iure laborum magni maxime nemo neque nostrum odio
                                        recusandae rem, tenetur voluptatum? Et fugit placeat quam quia. Autem, deserunt
                                        eius exercitationem necessitatibus nihil quisquam quos reprehenderit velit
                                        voluptatibus! Accusamus ad aperiam architecto aut blanditiis expedita illo
                                        impedit incidunt iusto minima molestias, mollitia nam necessitatibus obcaecati
                                        quis, reprehenderit repudiandae, sunt ut vel vero. <br/><br/>
                                    </div>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>}
                {this.state.showOverlay && <div className="overlay"/>}
                {this.state.showResultOverlay && <div className="result-overlay">
                    {this.state.cards.filter(card => card.matched).length === this.state.cards.length ? (

                        <div>
                            <div className="title">
                                Gratulálunk!
                            </div>
                            <div className="text">
                                Teljesítetted a(z) {this.state.level}. szintet. <br/>
                                <strong>Játssz tovább!</strong>
                            </div>
                        </div>
                    ) : (

                        <div>
                            <div className="title">
                                Ez most nem sikerült!
                            </div>
                        </div>
                    )}}
                    <div className="buttons">
                        <div className="button" onClick={() => this.chooseDifficulty()}>
                            újrajátszom
                        </div>
                        <div className="button" onClick={() => MenuService.setMenuLocation('/games/')}>
                            Vissza a többi játékhoz
                        </div>
                    </div>
                </div>}
                {this.state.showChooseScreen && <div className="choose-difficulty" style={{}}>
                    <div className="subtitle">
                        Válassz szintet!
                    </div>
                    <div className="buttons">
                        <div className="button" onClick={() => this.startGame(1)}>
                            <div className="top">
                                1. szint
                            </div>
                            <div className="bottom">
                                5 pár
                            </div>
                        </div>
                        <div className="button" onClick={() => this.startGame(2)}>
                            <div className="top">
                                2. szint
                            </div>
                            <div className="bottom">
                                10 pár
                            </div>
                        </div>
                        <div className="button" onClick={() => this.startGame(3)}>
                            <div className="top">
                                3. szint
                            </div>
                            <div className="bottom">
                                15 pár
                            </div>
                        </div>
                        <div className="button" onClick={() => this.startGame(4)}>
                            <div className="top">
                                4. szint
                            </div>
                            <div className="bottom">
                                20 pár
                            </div>
                        </div>
                        <div className="button" onClick={() => this.startGame(5)}>
                            <div className="top">
                                5. szint
                            </div>
                            <div className="bottom">
                                25 pár (nyereményért)
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}
