import React, {Component} from 'react';
import './home.scss'
import {MenuService, NavigationService} from './../actions'

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animate: 'in',
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                animate: ''
            })
        }, 200);
        this.sub = MenuService.subscribeMenuLocation(() => {
            this.animateOut()
        })

    }

    animateOut () {
        this.setState({
            animate: 'out'
        });
        setTimeout(() => {
            NavigationService.readyToNavidate(true)
        }, 500)
    }

    componentWillUnmount() {
        this.sub()
    }

    render() {
        return (
            <div className={'home ' + this.state.animate}>
                <div className="veszprem-logo">
                    <img src="/images/vp_logo.png" alt=""/>
                </div>
                <div className="home-text">
                    <h2>Tudj meg többet a</h2>
                    <h1>Csapatról!</h1>
                    <p>Lépj be a Telekom Veszprém világába! Ismerd meg jobban a klub történetét és a játékosokat!<br/>
                        Játssz velünk, derítsd ki többek között, hogy Te melyik poszton lennél a leghatékonyabb, vagy, hogy mekkora a tenyered kedvenc játékosod kezéhez képest!<br/>
                        <strong>Készen állsz? Kezdjük!</strong><br/>
                    </p>
                </div>
                <div className="telekom-logo">
                    <img src="/images/telekom_logo.svg" alt=""/>
                </div>
            </div>
        )
    }
}
