import React, {Component} from 'react'
import {api} from '../../common'
import Swiper from "@eredessil/react-id-swiper";
import "@eredessil/react-id-swiper/src/styles/scss/swiper.scss";
import {MenuService, NavigationService} from "../../actions";
import './goals.scss'

export class Goals extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        api('goals', element => this.setState({
            goals: element
        }))
    }

    componentDidMount() {
        //this.swiper.goTo(1);
        this.subscribe = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(() => {
                NavigationService.readyToNavidate(true);
            }, 300)
        })
    }

    componentWillUnmount() {
        this.subscribe();
    }

    render() {

        const params = {
            direction: 'vertical',
            slidesPerView: 'auto',
            freeMode: true,
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            mousewheel: true,
        };
        return (
            <div className={'goals'  + (this.state.animateOut ? ' out' : '')}>
                {this.state.goals && (
                    <div>
                        <div className="back-button" onClick={() => MenuService.setMenuLocation('/')}>
                            vissza
                        </div>
                        <div className="large-image">
                            <img src="/images/goals/goals.png" alt=""/>
                        </div>
                        <div className={"goal-list"}>
                            <div className="head">
                                <div className="number">#</div>
                                <div className="image"/>
                                <div className="name">Játékos</div>
                                <div className="position">Poszt</div>
                                <div className="point">Gólok</div>
                            </div>
                            <div className="content">
                                <Swiper {...params}>
                                    {this.state.goals.map((row, key) => (
                                            <div key={key} className="row">
                                                <div className="number">{key + 1},</div>
                                                <div className="image"><img src={'https://' + row.image3} alt=""/></div>
                                                <div className="name">{row.first_name} {row.last_name}</div>
                                                <div className="position">{row.position}</div>
                                                <div className="point">{row.goals}</div>
                                            </div>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}