import React, {Component} from 'react'
//import {api} from './../../common'
import './positions.scss'
import {MenuService, NavigationService} from "../../../actions";
import {api} from "../../../common";

export class Positions extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            isLeftHand: null,
            questions: [
                {
                    question: 'Milyen kezes vagy?',
                    answers:
                        [{
                            answer: 'Balkezes',
                            value: [
                                'shooter',
                                'center',
                                'edge',
                                'leader',
                                'goalkeeper'
                            ],
                            setHand: 'right'
                        }, {
                            answer: 'Kétbalkezes',
                            value: [
                                'helper'
                            ],
                            setHand: 'endTheGame'
                        }
                            , {
                            answer: 'Jobbkezes ',
                            value: [
                                'shooter',
                                'center',
                                'edge',
                                'leader',
                                'goalkeeper'
                            ],
                            setHand: 'left'
                        }]

                },
                {
                    question: 'Milyen magas vagy?',
                    answers:
                        [{
                            answer: 'Nagyon magas',
                            value: [
                                'shooter',
                                'center',
                                'goalkeeper'
                            ]
                        }, {
                            answer: 'Átlagos ',
                            value: [
                                'leader',
                                'edge'
                            ]
                        }
                            , {
                            answer: 'Alacsony ',
                            value: [
                                'helper',
                                'edge'
                            ]
                        }]
                },
                {
                    question: 'Milyenek a reflexeid?',
                    answers:
                        [{
                            answer: 'Két ujjal kapom el a repkedő rovarokat.',
                            value: [
                                'leader',
                                'goalkeeper'
                            ]
                        }, {
                            answer: 'Egy ütés elől simán elhajolok. ',
                            value: [
                                'center',
                                'edge',
                                'shooter'
                            ]
                        }
                            , {
                            answer: 'Ha dobnak nekem valamit, az általában leesik.',
                            value: [
                                'helper'
                            ]
                        }]

                },
                {
                    question: 'Mennyire vagy gyors?',
                    answers:
                        [{
                            answer: 'Ami másnak sprint, nekem laza kocogás.',
                            value: [
                                'edge',
                                'leader'
                            ]
                        }, {
                            answer: 'Az átlagnál gyorsabb vagyok.',
                            value: [
                                'shooter',
                                'center'
                            ]
                        }
                            , {
                            answer: 'Mire lehajolok, elszalad a csiga is.',
                            value: [
                                'helper',
                                'goalkeeper'
                            ]
                        }]

                },
                {
                    question: 'Milyen magasra tudsz ugrani?',
                    answers:
                        [{
                            answer: 'A lábaim mintha rugók lennének.',
                            value: [
                                'shooter',
                                'edge'
                            ]
                        }, {
                            answer: 'Egy zsámoly még simán belefér.',
                            value: [
                                'leader',
                                'goalkeeper',
                                'center'
                            ]
                        }
                            , {
                            answer: 'Ööö. Egy lajhár tud ugrani?',
                            value: [
                                'helper'
                            ]
                        }]

                }
            ],
            stage: 'intro',
            answers: [],
            currentQuestionId: 0,
            currentQuestion: null,
            showTheCorrectAnswer: false,
            stageAnimationTime: 300,
            animateState: '',
            timeLimit: 20,
            hand: '',
            result: null,
            playerPositions: null,
            positions: {
                shooter: 0,
                center: 0,
                edge: 0,
                leader: 0,
                helper: 0,
                goalkeeper: 0
            },
            timer: null,
            timeDelay: 3000,
            summary: 0,
            showQuestion: 'preface',
            resultImage: '',
            progressbar: new Array(5).fill({
                valid: null
            })
        };

        this.state = this.initialState;
    }

    resolvePosition(positionName) {
        let allPosition = [
            {name: 'Ballövő', key: 'shooter', hand: 'left'},
            {name: 'Balszélső', key: 'edge', hand: 'left'},
            {name: 'Beálló', key: 'center'},
            {name: 'Irányító', key: 'leader'},
            {name: 'Jobblövő', key: 'shooter', hand: 'right'},
            {name: 'Jobbszélső', key: 'edge', hand: 'right'},
            {name: 'Kapus', key: 'goalkeeper'}
        ];
        return allPosition.filter(item => item.name === positionName)[0]
    }

    componentWillMount() {

        let array = [];
        api('players', response => {
            response.filters.map(filter => array[filter.position] = array.push({
                name: filter.position,
                key: this.resolvePosition(filter.position),
                body: response.players.filter(player => player.name === filter.position && player.legendary === "0"
                )
            })) && this.setState({playerPositions: array});
        });

        this.sub = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(function () {
                NavigationService.readyToNavidate(true);
            }, 300)
        })
    }

    componentWillUnmount() {
        this.sub();
    }

    animateStageOut(callback, time) {
        this.setState({
            animateState: 'out'
        });
        setTimeout(() => {
            this.setState({
                animateState: ''
            });
            callback()
        }, time)
    }

    navigate() {
        this.setState({
            animateState: 'out'
        });
        setTimeout(() => {
            MenuService.setMenuLocation('/games/')
        }, this.state.stageAnimationTime)
    }


    setStage(stageName, time, cb) {
        cb = cb || null;
        time = time || this.state.stageAnimationTime;
        this.animateStageOut(() => {
            this.setState({
                stage: stageName,
            });
            if (cb) {
                cb()
            }
        }, time);
    }

    startTimer(callback) {
        this.setState({timer: this.state.timeLimit, showQuestion: ''});
        this.timeOut = setTimeout(() => {
            this.interval = setInterval(() => {
                if (this.state.timer > 0) {
                    this.setState({timer: this.state.timer - 1});
                } else {
                    if (this.state.hand === '') {
                        this.setState({
                            hand: 'left'
                        })
                    }
                    this.validateAnswer({value: ['helper', 'helper']});
                }
            }, 1000);
        }, 1000)
    }

    startQuiz() {
        this.getQuestion();
        this.setStage('question', 300, () => {
            setTimeout(() => {
                this.startTimer();
            }, 1000)
        })
    }

    endQuiz() {
        let result = this.processResult();
        clearInterval(this.interval);
        clearTimeout(this.timeOut);
        this.setState({result: result})
        this.setStage('summary', 600)
        setTimeout(() =>this.getPlayerData(), 0)
        setTimeout(() =>this.getPlayerData(), 0)
    }

    getQuestion() {
        if (this.state.questions[this.state.currentQuestionId]) {
            this.setState({
                currentQuestion: this.state.questions[this.state.currentQuestionId],
            })
        }
    }

    validateAnswer(value) {
        clearTimeout(this.timeOut);
        clearInterval(this.interval)
        let stateUpdate = this.state.progressbar,
            newPositions = this.state.positions;

        if (value.setHand) {
            this.setState({
                hand: value.setHand
            });
            if (value.setHand === 'endTheGame') {
                newPositions['helper'] = 3000;
                this.setState({
                    positions: newPositions
                });
                this.endQuiz();
                return false;
            }
        }

        stateUpdate[this.state.currentQuestionId] = 'true';
        value.value.map(key => newPositions[key]++);

        this.setState({
            positions: newPositions,
            progressbar: stateUpdate
        });

        if (this.state.currentQuestionId < this.state.questions.length - 1) {
            this.nextQuestion();
        } else {
            this.endQuiz();
        }


    }

    nextQuestion() {
        this.setState({
            showQuestion: 'after-face'
        })
        setTimeout(() => {
            this.setState({
                currentQuestionId: this.state.currentQuestionId + 1,
                timer: this.state.timeLimit,
                showTheCorrectAnswer: false,
                showQuestion: 'preface'

            });
            this.getQuestion();
            setTimeout(() => {
                this.startTimer()
            }, 600)
        }, 600)
    }

    processResult() {
        let result = 0,
            {hand} = this.state,
            {shooter, center, edge, leader, helper, goalkeeper} = this.state.positions;
        let position;

        if (result <= helper) {
            result = helper;
            position = 'helper'
        }
        if (result <= edge) {
            result = edge;
            position = 'edge'
        }
        if (result <= shooter) {
            result = shooter;
            position = 'shooter'
        }
        if (result <= center) {
            result = center;
            position = 'center'
        }
        if (result <= goalkeeper) {
            result = goalkeeper;
            position = 'goalkeeper'
        }
        if (result <= leader) {
            position = 'leader'
        }

        this.setState({
            position: position
        })

        let translatedPosition;
        switch (position) {
            case 'shooter':
                if (hand === 'left') {
                    translatedPosition = 'ballövő';
                    break;
                } else {
                    translatedPosition = 'jobblövő';
                    break;
                }
            case 'edge':
                if (hand === 'left') {
                    translatedPosition = 'balszélső';
                    break;
                } else {
                    translatedPosition = 'jobbszélső';
                    break;
                }
            case 'center':
                translatedPosition = 'beálló';
                break;
            case 'leader':
                translatedPosition = 'irányító';
                break;
            case 'goalkeeper':
                translatedPosition = 'Kapus';
                break;
            default:
                translatedPosition = 'szertáros';
                break;

        }

        this.setState({
            translatedResult: {
                name: translatedPosition,
                key: position,
           //     resultString: this.getPlayer()
            }
        });
        return translatedPosition;
    };
    getPlayerData() {
        let baar = this.state;
        // eslint-disable-next-line
        let item = this.state.playerPositions.filter(item => {
            if (item.key.key === baar.position) {
                console.log(item, baar.position);
                if (!item.key.hand ) {
                    return item
                } else if (item.key.hand === baar.hand){
                    return item
                }
            }
        })[0];
        if (item) {
            item = item.body[Math.floor(Math.random()*item.body.length)];
            this.setState({
                resultString: item.last_name + ' ' + item.first_name,
                resultImage: 'https://' + item.image
            })
        } else {
            this.setState({
                resultString: 'a Béla bácsi :)'
            })
        }

    }
    resetQuiz() {
        // api
        this.setStage('intro', 300, () => this.setState({
                answers: [],
                currentQuestionId: 0,
                currentQuestion: null,
                stageAnimationTime: 300,
                animateState: '',
                timer: null,
                showTheCorrectAnswer: false,
                summary: 0,
                progressbar: new Array(10).fill({
                    valid: null
                })
            }
        ))
    }

    render() {
        return (
            <div className={"positions " + (this.state.animateOut ? ' out' : '') + ' ' + this.state.showQuestion}>
                <div className="back-button" onClick={() => MenuService.setMenuLocation('/games/')}>
                    Vissza
                </div>
                <div className={"animate-wrapper " + (this.state.animateState ? this.state.animateState : '')}>

                    {/* intró */}
                    {this.state.stage === 'intro' &&
                    <div className="intro-container">
                        <div className="text-section">
                            <div className="title">Melyik poszton játszanál?</div>
                            <div className="text">
                                <strong>
                                    Most megtudhatod, hozzád melyik poszt passzol a legjobban! <br/>
                                    Válaszolj a kérdésekre és derítsd ki, hogy milyen <br/>
                                    szereped lenne egy kézilabdacsapatban!
                                </strong>
                            </div>
                            <div className="position-list">
                                {this.state.playerPositions && this.state.playerPositions.map((position, key) =>
                                    (

                                        <div className="position" key={key}>
                                            <div className="number">{key + 1}.</div>
                                            <strong>{position.name}</strong>
                                            <div className="text">
                                                {position.body.map((player, key) =>
                                                    <span
                                                        key={key}>{player.first_name + ' ' + player.last_name}{position.body.length > key + 1 ? ', ' : ''}</span>)}
                                            </div>
                                        </div>
                                    )
                                )
                                }
                            </div>
                        </div>
                        <div className="image-section">
                            <img src="/images/games/positions/positions.png" alt=""/>
                        </div>
                        <div className="start-the-game" onClick={() => this.startQuiz('question', 300)}>
                            Játszom
                        </div>
                    </div>}

                    {/* kérdések */}
                    {this.state.stage === 'question' &&
                    <div className="question-container">
                        <div className="question-wrapper">
                            <div className="question-title">
                                <div className="title-wrapper">{this.state.currentQuestion.question}</div>
                            </div>
                            <div className="question-timer">
                                {this.state.timer}
                            </div>
                            <div className="answer-cards">
                                {this.state.currentQuestion.answers.map((choice, key) =>
                                    <div
                                        key={key}
                                        className={'answer-card ' + ((this.state.showTheCorrectAnswer) && (choice.isTrue === '1' ? ' good-answer' : ' wrong-answer'))}
                                        onClick={() => {
                                            this.validateAnswer(choice);
                                        }}

                                    >
                                        <div className="card-front">
                                            <svg width="433" height="483" viewBox="0 0 433 483" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d)">
                                                    <path
                                                        d="M363 126.907C363 126.191 362.617 125.53 361.997 125.173L197.449 30.5732C196.831 30.2183 196.072 30.2184 195.454 30.5735L31.0027 125.173C30.3824 125.53 30 126.191 30 126.907V316.093C30 316.809 30.3824 317.47 31.0027 317.827L195.454 412.426C196.072 412.782 196.831 412.782 197.449 412.427L361.997 317.827C362.617 317.47 363 316.809 363 316.093V126.907Z"
                                                        fill="#DA0000" fillOpacity="1"/>
                                                    <path
                                                        d="M361.498 126.04C361.809 126.219 362 126.549 362 126.907V316.093C362 316.451 361.809 316.781 361.498 316.96L196.95 411.56C196.641 411.737 196.262 411.737 195.953 411.56L31.5014 316.96C31.1912 316.782 31 316.451 31 316.093V126.907C31 126.549 31.1912 126.218 31.5014 126.04L195.953 31.4403C196.262 31.2628 196.641 31.2627 196.95 31.4402L361.498 126.04Z"
                                                        stroke="url(#paint0_linear)" strokeWidth="2"/>
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d" x="0" y="0.307129" width="433"
                                                            height="482.386"
                                                            filterUnits="userSpaceOnUse"
                                                            colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                        <feOffset dx="20" dy="20"/>
                                                        <feGaussianBlur stdDeviation="25"/>
                                                        <feColorMatrix type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                                 result="effect1_dropShadow"/>
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                                 in2="effect1_dropShadow"
                                                                 result="shape"/>
                                                    </filter>
                                                    <linearGradient id="paint0_linear" x1="279" y1="76" x2="109"
                                                                    y2="358"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0110497" stopColor="#B70000"/>
                                                        <stop offset="0.508287" stopColor="#EA0000"/>
                                                        <stop offset="1" stopColor="#B70000"/>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <div className="answer">
                                                {choice.answer}
                                            </div>
                                        </div>
                                        <div className="card-back">
                                            <svg width="433" height="483" viewBox="0 0 433 483" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d)">
                                                    <path
                                                        d="M363 126.907C363 126.191 362.617 125.53 361.997 125.173L197.449 30.5732C196.831 30.2183 196.072 30.2184 195.454 30.5735L31.0027 125.173C30.3824 125.53 30 126.191 30 126.907V316.093C30 316.809 30.3824 317.47 31.0027 317.827L195.454 412.426C196.072 412.782 196.831 412.782 197.449 412.427L361.997 317.827C362.617 317.47 363 316.809 363 316.093V126.907Z"
                                                        fill={choice.isTrue === '1' ? '#fff' : "#DA0000"}
                                                        fillOpacity="1"/>
                                                    <path
                                                        d="M361.498 126.04C361.809 126.219 362 126.549 362 126.907V316.093C362 316.451 361.809 316.781 361.498 316.96L196.95 411.56C196.641 411.737 196.262 411.737 195.953 411.56L31.5014 316.96C31.1912 316.782 31 316.451 31 316.093V126.907C31 126.549 31.1912 126.218 31.5014 126.04L195.953 31.4403C196.262 31.2628 196.641 31.2627 196.95 31.4402L361.498 126.04Z"
                                                        stroke="url(#paint0_linear)" strokeWidth="2"/>
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d" x="0" y="0.307129" width="433"
                                                            height="482.386"
                                                            filterUnits="userSpaceOnUse"
                                                            colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                        <feOffset dx="20" dy="20"/>
                                                        <feGaussianBlur stdDeviation="25"/>
                                                        <feColorMatrix type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                                 result="effect1_dropShadow"/>
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                                 in2="effect1_dropShadow"
                                                                 result="shape"/>
                                                    </filter>
                                                    <linearGradient id="paint0_linear" x1="279" y1="76" x2="109"
                                                                    y2="358"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0110497" stopColor="#B70000"/>
                                                        <stop offset="0.508287" stopColor="#EA0000"/>
                                                        <stop offset="1" stopColor="#B70000"/>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <div className="answer">
                                                {choice.answer}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="image-wrapper">
                            <img src="/images/games/positions/positions_game.png" alt=""/>
                        </div>
                        <div className="progress-icons">
                            {
                                this.state.progressbar.map((progressState, key) => {
                                    if (progressState === 'true') {
                                        return (<div className="icon complete" key={key}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 8.42285L5.51305 11.9359L12 5.57715" stroke="white"
                                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </div>)
                                    } else if (progressState === 'lie') {
                                        return (
                                            <div className="icon complete">
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 12.8984L13 2.11584" stroke="white" strokeWidth="2"
                                                           strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M2.2168 2L12.7678 12.7638" stroke="white" strokeWidth="2"
                                                           strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div
                                                key={key}
                                                className={"icon " + (this.state.currentQuestionId === key ? 'active' : '')}>
                                                {key + 1}
                                            </div>
                                        )
                                    }
                                })
                            }

                        </div>
                    </div>}

                    {/* kiértékelés */}
                    {this.state.stage === 'summary' &&
                    <div className="summary-container">
                        <div className="content-wrapper">
                            <div className="texts">
                                <div className="title">Gratulálunk!</div>
                                <div className="subtitle">A csapatban {this.state.translatedResult.name} lennél, mint {this.state.resultString}</div>
                            </div>
                            <div className="playground">
                                <div className="positions">
                                    {this.state.position === 'edge' && this.state.hand === 'left' ? ' active' : ''}
                                    <div
                                        className={"position left-edge" + (this.state.position === 'edge' && this.state.hand === 'left' ? ' active' : '')}>Balszélső
                                        <div className="ping"/>
                                    </div>
                                    <div
                                        className={"position left-shooter" + (this.state.position === 'shooter' && this.state.hand === 'left' ? ' active' : '')}>Ballövő
                                        <div className="ping"/>
                                    </div>
                                    <div
                                        className={"position leader" + (this.state.position === 'leader' ? ' active' : '')}>Irányító
                                        <div className="ping"/>
                                    </div>
                                    <div
                                        className={"position center" + (this.state.position === 'center' ? ' active' : '')}>
                                        <div className="ping"/>
                                        Beálló
                                    </div>
                                    <div
                                        className={"position right-shooter" + (this.state.position === 'shooter' && this.state.hand === 'right' ? ' active' : '')}>Jobblövő
                                        <div className="ping"/>
                                    </div>
                                    <div
                                        className={"position right-edge" + (this.state.position === 'edge' && this.state.hand === 'right' ? ' active' : '')}>Jobbszélső
                                        <div className="ping"/>
                                    </div>
                                    <div
                                        className={"position goalkeeper" + (this.state.position === 'goalkeeper' ? ' active' : '')}>
                                        <div className="ping"/>
                                        Kapus
                                    </div>
                                    {this.state.position === 'helper' && <div
                                        className={"position helper" + (this.state.position === 'helper' ? ' active' : '')}>
                                        <div className="ping"/>
                                        Szertáros</div>}
                                    <div className="enemy enemy-1"/>
                                    <div className="enemy enemy-2"/>
                                    <div className="enemy enemy-3"/>
                                    <div className="enemy enemy-4"/>
                                    <div className="enemy enemy-5"/>
                                    <div className="enemy enemy-6"/>
                                    <div className="enemy enemy-7"/>
                                </div>
                                <svg width="827" height="442" viewBox="0 0 827 442" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.84668" width="826" height="440.515" fill="#000"
                                          style={{opacity: 0.2}}/>
                                    <rect x="0.5" y="0.84668" width="826" height="440.515" stroke="white"/>
                                    <rect x="39.0996" y="35.7119" width="748.799" height="371.532" fill="#000"
                                          style={{opacity: 0.2}}/>
                                    <rect x="39.0996" y="35.7119" width="748.799" height="371.532" stroke="white"
                                          strokeWidth="2"/>
                                    <line x1="414.374" y1="34.7119" x2="414.374" y2="408.244" stroke="white"/>
                                    <path
                                        d="M38.5996 35.2119H60.6714C143.238 35.2119 210.171 102.145 210.171 184.712V258.244C210.171 340.811 143.238 407.744 60.6714 407.744H38.5996V35.2119Z"
                                        fill="#000" style={{opacity: 0.2}}/>
                                    <path
                                        d="M38.5996 35.2119H60.6714C143.238 35.2119 210.171 102.145 210.171 184.712V258.244C210.171 340.811 143.238 407.744 60.6714 407.744H38.5996V35.2119Z"
                                        stroke="white"/>
                                    <path
                                        d="M38.5996 80.0367C101.671 80.3058 152.747 131.461 152.855 194.596L152.9 221.477V221.479L152.853 248.371C152.74 311.501 101.666 362.65 38.5996 362.919V80.0367Z"
                                        fill="#000" style={{opacity: 0.2}}/>
                                    <path
                                        d="M38.5996 80.0367C101.671 80.3058 152.747 131.461 152.855 194.596L152.9 221.477V221.479L152.853 248.371C152.74 311.501 101.666 362.65 38.5996 362.919V80.0367Z"
                                        stroke="white"/>
                                    <rect x="38.5" y="179.5" width="21" height="83" stroke="white"/>
                                    <path
                                        d="M788.4 408.491L766.329 408.491C683.762 408.491 616.829 341.557 616.829 258.991L616.829 185.459C616.829 102.892 683.762 35.9586 766.329 35.9586L788.4 35.9586L788.4 408.491Z"
                                        fill="#000" style={{opacity: 0.2}}/>
                                    <path
                                        d="M788.4 408.491L766.329 408.491C683.762 408.491 616.829 341.557 616.829 258.991L616.829 185.459C616.829 102.892 683.762 35.9586 766.329 35.9586L788.4 35.9586L788.4 408.491Z"
                                        stroke="white"/>
                                    <path
                                        d="M788.4 363.666C725.329 363.397 674.253 312.241 674.145 249.107L674.1 222.226L674.1 222.224L674.148 195.331C674.26 132.202 725.334 81.0527 788.4 80.7837L788.4 363.666Z"
                                        fill="#000" style={{opacity: 0.2}}/>
                                    <path
                                        d="M788.4 363.666C725.329 363.397 674.253 312.241 674.145 249.107L674.1 222.226L674.1 222.224L674.148 195.331C674.26 132.202 725.334 81.0527 788.4 80.7837L788.4 363.666Z"
                                        stroke="white"/>
                                    <rect x="767.5" y="179.5" width="21" height="83" stroke="white"/>
                                </svg>
                            </div>
                            <div className="button" onClick={() => MenuService.setMenuLocation('/games/')}>
                                Vissza a többi játékhoz
                            </div>
                        </div>
                        <div className="images">
                            {this.state.resultImage &&
                            <img src={this.state.resultImage } alt="" className="right"/>}
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }
}
