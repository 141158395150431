export default class CardData {

    static s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(36)
            .substring(0)
            .toUpperCase()
    }
    static shuffleArray = arr => arr
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1]);

    static imageData  = [
        ...CardData.shuffleArray([
            '/images/games/memory/cards/1.png?',
            '/images/games/memory/cards/2.png?',
            '/images/games/memory/cards/3.png?',
            '/images/games/memory/cards/4.png?',
            '/images/games/memory/cards/5.png?',
            '/images/games/memory/cards/6.png?',
            '/images/games/memory/cards/7.png?',
            '/images/games/memory/cards/8.png?',
            '/images/games/memory/cards/9.png?',
            '/images/games/memory/cards/10.png?',
            '/images/games/memory/cards/11.png?',
            '/images/games/memory/cards/12.png?',
            '/images/games/memory/cards/13.png?',
            '/images/games/memory/cards/14.png?',
            '/images/games/memory/cards/15.png?',
            '/images/games/memory/cards/16.png?',
            '/images/games/memory/cards/17.png?',
            '/images/games/memory/cards/18.png?',
            '/images/games/memory/cards/19.png?',
            '/images/games/memory/cards/20.png?',
            '/images/games/memory/cards/21.png?',
            '/images/games/memory/cards/22.png?',
            '/images/games/memory/cards/23.png?',
            '/images/games/memory/cards/24.png?',
            '/images/games/memory/cards/25.png?',
        ])
    ];

    static renderArray(length) {
        const array = [];
        for (let i = 0; i < length; i++) {
            array.push(
                {
                    pairId: i,
                    image: CardData.imageData[i],
                    matched: false
                },
                {
                    pairId: i,
                    image: CardData.imageData[i],
                    matched: false
                })
        }
        return array;
    }


    static getCardList (length) {
        return  CardData.shuffleArray(CardData.renderArray(length))
    }
}
