import React, {Component} from 'react'
import './background.scss';
import {MenuService} from "../../actions";

export class Background extends Component {
    constructor(porps) {
        super(porps);
        this.state = {
            home: true
        };
    }

    /*
    animateBg(cb) {
        cb();
    }
    */

    componentDidMount() {

        this.listener = MenuService.subscribeMenuLocation(() => {
            if ( MenuService.getMenuLocation() === '/') {
                this.setState({home: true})
            } else {
                this.setState({home: false})
            }
        })
    }

    componentWillUnmount() {
        this.listener()
    };

    chandePhase() {
        /**
         * phases: home. ..stb
         *
         */

        // animate out current phase

        // animate in current phase

    }

    render() {
        return (
            <div className={'background'}>
                <div className="background-wrapper">
                    <div className="player-bg">
                        <div className="version-home" style={{backgroundImage: 'url(/images/home/bg.png)'}}/>
                        <img className={'version-subpage' + (!this.state.home ? ' show' : '')} alt="" src={'/images/home-bg-v2.png'}/>
                    </div>
                    <div className={'home-bg-wrapper' + (this.state.home ? ' show' : '')}>
                        <img className={'player-1'} src="/images/home/player-1.png" alt=""/>
                        <img className={'player-4'} src="/images/home/player-4.png" alt=""/>
                        <img className={'player-3'} src="/images/home/player-3.png" alt=""/>
                        <img className={'player-2'} src="/images/home/player-2.png" alt=""/>
                    </div>
                </div>
                {this.props.children}
            </div>
        )
    }
}
