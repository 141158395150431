const APIURL = process.env.REACT_APP_NODE_ENV.trim() === 'dev'
    ? 'https://kiosk.webdreamdev.hu/api/'
    : 'https://telekom-veszprem-kiosk.webdream.hu/api/';
let storageState;
try {
    window.storage = window.require('electron-json-storage');
    const os = require('os');
    window.storage.setDataPath(os.tmpdir());
    storageState = true;
    window.storage.getDataPath();
} catch (e) {
    storageState = false;
}

export const api = async (endpoint, cb) => {
    fetch(APIURL + endpoint + '?token=GMw1srSZ')
        .then(response => response.json())
        .then(response => {
            if (storageState) {
                window.storage.set(endpoint.replace('/', '-'), response);
            }
            return cb(response)
        }).catch(error => {
            storageState && window.storage.get(endpoint.replace('/', '-'), (error, data) => {
                if (Object.keys(data).length) {
                    console.log(data, error)
                    return cb(data)
                } else {
                    console.log('error');
                    return cb([])
                }
        })
    })
};

export const postApi = (cb, body = {}, error) => {
    fetch(APIURL + 'code?token=GMw1srSZ', {
        method: 'POST',
        body: JSON.stringify(body)
    })
        .then(response => response.json())
        .then(response => cb(response))
        .catch(() => {
            error();
        });
};
