import React, {Component} from "react";
import Swiper from "@eredessil/react-id-swiper";
import "@eredessil/react-id-swiper/src/styles/scss/swiper.scss";
import {AnimatedFadeRight} from "..";
import './drawTeamTable.scss';

export class DrawTeamTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    renderTables(data) {
        return (
            data.map((table, key)=> {
                return (
                    <div className="table" key={key}>
                        <div className="table-head">
                            <div className="cell cell-team">
                                <strong># </strong> {table.group ? table.group : 'Csapat'}
                            </div>
                            <div className="cell cell-playoff">
                                Play off
                            </div>
                            <div className="cell cell-played-match">
                                Lejátszott m.
                            </div>
                            <div className="cell cell-wins">
                                Győzelem
                            </div>
                            <div className="cell cell-draw">
                                Döntetlen
                            </div>
                            <div className="cell cell-loose">
                                Vereség
                            </div>
                            <div className="cell cell-goal-rate">
                                Gólarány
                            </div>
                            <div className="cell cell-points-rate">
                                Pont
                            </div>
                            <div className="cell cell-form">Forma (utolsó 5 m.)</div>
                        </div>
                        <div className="tabla-body">
                            {table.league && table.league.map((row, key) => {
                                return (<div className={'tabla-body'} key={key}>
                                        <AnimatedFadeRight count={key} time={100} delay={40}>
                                        <div className={"table-row" + (row.highlighted ? ' highlighted' : '')}>
                                            <div className="cell cell-team">
                                                <strong>{key + 1}.</strong> {row.team}
                                            </div>
                                            <div className="cell cell-playoff">
                                                {row.play_off}
                                            </div>
                                            <div className="cell cell-played-match">
                                                {row.matches}
                                            </div>
                                            <div className="cell cell-wins">
                                                {row.victories}
                                            </div>
                                            <div className="cell cell-draw">
                                                {row.draws}
                                            </div>
                                            <div className="cell cell-loose">
                                                {row.defeats}
                                            </div>
                                            <div className="cell cell-goal-rate">
                                                {row.goals1 + ':' + row.goals2}
                                            </div>
                                            <div className="cell cell-points-rate">
                                                {row.points}
                                            </div>
                                            <div className="cell cell-form">
                                                {row.form && row.form.length > 0 && row.form.split(',').map((form, key) =>
                                                    key < 5
                                                        ? <div className="cell-form-field" key={key}>{form}</div>
                                                        : ''
                                                )}
                                            </div>
                                        </div>
                                        </AnimatedFadeRight>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })
        )
    }

    render() {
        const params = {
            direction: 'vertical',
            slidesPerView: 'auto',
            freeMode: true,
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            mousewheel: true,
        };
        return (
            <div className="tabella">
                {this.props.scrollbar ?
                    <Swiper {...params}>
                        {this.renderTables(this.props.data)}
                    </Swiper>
                    : this.renderTables(this.props.data)}
            </div>
        )
    }
}
