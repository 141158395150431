import React, {Component} from 'react'
import './menu.scss';
import MenuItem from './menuItem';
import {MenuService, NavigationService} from "../../actions";
import {withRouter} from 'react-router-dom'

const mock = [
    {
        name: 'Játékosok',
        description: 'Civilben és a pályán',
        location: '/players'
    },
    {
        name: 'Kupák',
        description: 'Hamarosan...',
        //description: 'A csapat eddigi büszkeségei,\n eredményei',
        //location: '/cups',

    },
    {
        name: 'Csapat',
        description: 'A klub története, érdekességek',
        location: '/team',
    },
    {
        name: 'Játékok',
        description: 'Teszteld a tudásod, és nyerj értékes\n szurkolói ajándékokat!',
        location: '/games'

    },
    {
        name: 'Bajnokságok',
        description: 'Tabellák, meccsnaptárak',
        location: '/championship'
    },
    {
        name: 'A telekom aktivitásai',
        magenta: true,
        location: '/telekom'
    },
    {
        name: 'Góllövő lista',
        description: 'Kövesd nyomon \nkedvenceid teljesítményét!',
        location: '/goals'
    },
];

class Menu extends Component {
    constructor(porps) {
        super(porps);
        this.state = {
            open: false,
            menuTextIsVisible: true,
            currentMenu: 'home',
            closeTime: 850,
            menuState: 'closed',
            menuStateClass: '',
            backButton: false,
            menuItems: mock
        }
    }

    componentDidMount() {
        NavigationService.listenReadyToNavigate(cb => {
            if (NavigationService.getReadyToNavigate()) {
                const url = MenuService.getMenuLocation();
                if (url === '/') {
                    this.setState({backButton: false});
                } else {
                    this.setState({backButton: true});
                }
                this.props.history.push(url);
                setTimeout(() => {
                    NavigationService.readyToNavidate(false);
                    MenuService.setMenuState('close:start');
                }, 0)
            }
        });

     // MenuService.setMenuLocation('/');
     // NavigationService.readyToNavidate(true)
    }

    openMenu() {
        MenuService.setMenuState('open:start');
        this.setState({
            open: true,
            menuState: 'open'
        })
        setTimeout(() => {
            this.setState({
                menuStateClass: 'open'
            })
        }, 10)
        setTimeout(() => {
            MenuService.setMenuState('open:end');
            this.setState({
                menuState: 'opened'
            })
        }, this.state.menuItems.length * 50 + 500)
    }

    toggleMenu() {
        this.state.open
            ? this.closeMenu()
            : this.openMenu();
    }

    closeMenu(item) {
        if (item && !item.location) {
            return false
        }
        MenuService.setMenuState('close:start');
        this.setState({
            open: false,
            menuState: 'close',
            menuStateClass: 'close'
        })
        setTimeout(() => {
            MenuService.setMenuState('close:end');
            this.setState({
                menuState: 'closed'
            })
        }, this.state.closeTime)
    }

    animateOut() {
        setTimeout(() => {
            NavigationService.readyToNavidate(true)
        }, 500)
    }

    render() {
        return (
            <div className={'menu'}>
                <div className={"menu-open-button" + (this.state.backButton ? '' : ' show-bg')} onClick={() => {
                    this.toggleMenu()
                }}>
                    <div className={'menubar' + (this.state.open === true ? ' open' : '')}>
                        <span className="snack-bar"/>
                        <span className="snack-bar"/>
                        <span className="snack-bar"/>
                        {this.state.menuTextIsVisible ?
                            <div className="text">
                                <p className={'opened'}>Bezárás</p>
                                <p className={'closed'}>menu</p>
                            </div>
                            : (
                                <div className="text"/>
                            )}
                    </div>
                </div>
                <div className={'menu-content'}>
                    {this.state.menuItems.map((item, key) => (
                            <div key={key} onClick={() => this.closeMenu(item)}>
                                <MenuItem open={this.state.open} item={item} position={key}/>
                            </div>
                        )
                    )}
                </div>
                {this.state.menuState !== 'closed' && (
                    <div className={'overlay '
                    + this.state.menuStateClass}
                         onClick={() => this.closeMenu()}
                    >
                    </div>
                )}
            </div>
        )
    }
};

export default withRouter(Menu);
