import React, {Component} from 'react';
import {MenuService, NavigationService} from "../../actions";
import Swiper from '@eredessil/react-id-swiper';
import {api} from '../../common';
import './cups.scss';

export class Cups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cups: []
        };

        api('cups', response => this.setState({cups: response}))
    }

    componentDidMount() {
        //this.swiper.goTo(1);
        this.subscribe = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(() => {
                NavigationService.readyToNavidate(true);
            }, 300)
        })
    }

    componentWillUnmount() {
        this.subscribe();
    }


    render() {
        const params = {
            slidesPerView: 3,
            centeredSlides: true,
            rebuildOnUpdate: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        };
        return (
            <div className={'cups' + (this.state.animateOut ? ' out' : '')}>

                <div className="back-button" onClick={() => MenuService.setMenuLocation('/')}>
                    Vissza
                </div>
                <Swiper {...params}>
                    {this.state.cups.map((cup, index) => {
                        return (
                            <div key={index}>
                                <div className={'cup'}>
                                    <div className="top">
                                        <div className="multiplier">{cup.multiplier}x</div>
                                        <div className="name">{cup.name}</div>
                                        <div className="text">{cup.text}</div>
                                    </div>
                                    <div className="bottom">
                                        <div className="image"><img
                                            style={{
                                                WebkitMaskImage: 'url(https://' + cup.image + ')'
                                            }}
                                            src={'https://' + cup.image}
                                            alt=""/>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </Swiper>
            </div>
        )
    }
}