import React, {Component} from 'react';
import './games.scss';
import {MenuService, NavigationService} from "../../actions";
import {BottomAngle} from "../../component/bottom-angle";

export class Games extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animateOut: false
        }
    }

    componentDidMount() {
        this.substribe = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(function () {
                NavigationService.readyToNavidate(true);
            }, 600)
        })
    }

    componentWillUnmount() {
        this.substribe();
    }

    render() {
        return (
            <div className={'game' + (this.state.animateOut ? ' out' : '')}>
                <div className="back-button" onClick={() => MenuService.setMenuLocation('/')}>
                    Vissza
                </div>
                <img className={'player-left'} src="/images/games/left.png" alt=""/>
                <img className={'player-right'} src="/images/games/right.png" alt=""/>
                <div className="game-menu">
                    <GamesMenuItem
                        left={'619px'}
                        top={'217px'}
                        onClick={() => MenuService.setMenuLocation('/games/positions')}
                    >
                        <div className="name">
                            Melyik poszton<br/>
                            Játszanál?<br/>
                        </div>
                    </GamesMenuItem>
                    <GamesMenuItem
                        onClick={() => MenuService.setMenuLocation('/games/quiz')}
                        left={'969px'}
                        top={'217px'}
                    >
                        <div className="name">
                            Kvízmester <br/>
                        </div>

                    </GamesMenuItem>
                    <GamesMenuItem
                        left={'794px'}
                        top={'517px'}
                        onClick={() => MenuService.setMenuLocation('/games/memory')}
                    >
                        <div className="name">
                            Memóriajáték<br/>
                        </div>
                    </GamesMenuItem>
                </div>
                <BottomAngle title={'JÁTSSZ ÉS NYERJ...'} content={'<strong>...értékes szurkolói ajándékokat</strong> ' +
                'vagy <strong>kerülj fel a ranglistára</strong> a legjobbak közé. <br>' +
                '                Te vagy a legnagyobb rajongó? Mutasd meg mennyit tudsz a csapatról!'}/>
            </div>
        )
    }
}

class GamesMenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        return (
            <div className="game-menu-item"
                 style={{
                     left: this.props.left,
                     top: this.props.top,
                     ...this.props.style
                 }}
                 onClick={this.props.onClick}
            >
                <div className="content">
                    {this.props.children}
                </div>
                <div className="bg-wrapper">
                    <svg className={'menu-item-bg'} width="373" height="423" viewBox="0 0 373 423" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d)">
                            <path
                                d="M343 106.907C343 106.191 342.617 105.53 341.997 105.173L177.449 10.5732C176.831 10.2183 176.072 10.2184 175.454 10.5735L11.0027 105.173C10.3824 105.53 10 106.191 10 106.907V296.093C10 296.809 10.3824 297.47 11.0027 297.827L175.454 392.426C176.072 392.782 176.831 392.782 177.449 392.427L341.997 297.827C342.617 297.47 343 296.809 343 296.093V106.907Z"
                                fill="url(#paint0_radial)"/>
                            <path
                                d="M341.498 106.04C341.809 106.219 342 106.549 342 106.907V296.093C342 296.451 341.809 296.781 341.498 296.96L176.95 391.56C176.641 391.737 176.262 391.737 175.953 391.56L11.5014 296.96C11.1912 296.782 11 296.451 11 296.093V106.907C11 106.549 11.1912 106.218 11.5014 106.04L175.953 11.4403C176.262 11.2628 176.641 11.2627 176.95 11.4402L341.498 106.04Z"
                                stroke="url(#paint1_linear)"
                                strokeWidth="2"/>
                        </g>
                        <defs>
                            <filter id="filter0_d" x="0" y="0.307129" width="373" height="422.386"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                <feOffset dx="10" dy="10"/>
                                <feGaussianBlur stdDeviation="10"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                            </filter>
                            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(176.5 201.5) rotate(90) scale(191.5 166.5)">
                                <stop stopColor="#DF0000"/>
                                <stop offset="1" stopColor="#C40000"/>
                            </radialGradient>
                            <linearGradient id="paint1_linear" x1="176.5" y1="10" x2="176.5" y2="393"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#B70000"/>
                                <stop offset="0.497238" stopColor="#E80000"/>
                                <stop offset="1" stopColor="#B70000"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        )
    }
}
