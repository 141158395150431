import { createStore } from 'redux'


function menuReducer(state = {menuState: 'sdf', navigationState: '/'}, action) {
    switch (action.type) {
        case 'MENU_STATE_CHANGE':
            return state = Object.assign(state, action.state);
        case 'NAVIGATION_CHANGE':
            return  state = Object.assign(state, action.state);
        default:
            return state
    }
}
function locationReducer(state = {}, action) {
    switch (action.type) {
        case 'LOCATION_CHANGE':
            return state = action.state;
        default:
            return state
    }
}

function navigateReducer(state = [], action) {
    switch (action.type) {
        case 'NAVIGATE':
            return state = action.state;
        default:
            return state
    }
}
function backButtonReducer(state = [], action) {
    switch (action.type) {
        case 'LOCATION_CHANGE':
            return state = action.state;
        default:
            return state
    }
}

export const menuStore = createStore(menuReducer, {menuState: 'untouched'});
export const locationStore = createStore(locationReducer, '');
export const navigateStore = createStore(navigateReducer, false);
export const backButtonStore = createStore(backButtonReducer, {location: '/'});


