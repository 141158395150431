import React, {Component} from "react";
import Swiper from "@eredessil/react-id-swiper";
import "@eredessil/react-id-swiper/src/styles/scss/swiper.scss";
import './match-calendar.scss';
import {AnimatedScaleIn} from "..";

export class MatchCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    printDate (startDate) {
        let dateObject = new Date(startDate);
        let day = dateObject.toLocaleDateString('hu-hu', {
            weekday: 'long'
        })
        let date = dateObject.toLocaleDateString('hu-hu', {
            month: '2-digit',
            day: '2-digit'
        })
        let hour = dateObject.toLocaleTimeString('hu-hu', {
            minute: 'numeric', hour: 'numeric'
        })
        return (
            <div>
                <strong> {date} </strong> {day} {hour}
            </div>
        )
    }

    renderTables(data) {
        const params = {
            direction: 'vertical',
            slidesPerView: 'auto',
            freeMode: true,
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            mousewheel: true,
            activeSlideKey: this.props.activeKey.toString() || '0'
        };
        return (
            <div className="table">
                <div className="tabla-body">
                    <Swiper {...params}>
                        {data.map((row, key) => {
                            return (<div className={'tabla-body'} key={key}>
                                    <AnimatedScaleIn count={key} time={100} delay={40}>
                                        <div className={"table-row" + (!row.result_home && !row.result_home ? ' highlighted' : '')}>
                                            <div className="cell cell-date">
                                                {this.printDate(row.date)}
                                            </div>
                                            <div className="wrapper">
                                                <div className="cell cell-logo">
                                                    <img src={'https://' + row.home_logo} alt="" style={{
                                                        width: 50,
                                                        height: 50
                                                    }}/>
                                                </div>
                                                <div className="cell cell-team-1">
                                                    {row.home} <br/>
                                                    {row.round} <i>(hazai)</i>
                                                </div>
                                                <div className="cell cell-points">
                                                    {row.result_home}
                                                </div>
                                                <div className="cell cell-points">
                                                    {row.result_away}
                                                </div>
                                                <div className="cell cell-team-2">
                                                    {row.away} <br/>
                                                    {row.round} <i>(vendég)</i>
                                                </div>
                                                <div className="cell cell-logo">
                                                    <img src={'https://' + row.away_logo} alt="" style={{
                                                        width: 50,
                                                        height: 50
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </AnimatedScaleIn>
                                </div>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        )
    }

    render() {

        return (
            <div className="matches">
                {this.props.scrollbar ?
                    this.renderTables(this.props.data)
                    : this.renderTables(this.props.data)}
            </div>
        )
    }
}
