import {menuStore, locationStore, navigateStore, backButtonStore} from "../stores";

export class MenuService {
    static setState(store, state, event) {
        store.dispatch({
                type: event,
                state: state
        })
    }

    static subscribeToAState(store, state, cb, stateName) {
        store.subscribe(() => {
            if (store.getState()[stateName] === state) {
                return cb(store.getState()[stateName])
            }
            return null
        });
    }

    static subscribeToAStore(store, cb) {
        store.subscribe(() => {
            cb(store.getState())
        });
    }

    static setMenuState(state) {
        MenuService.setState(menuStore, state, 'menuState', '')
        let obj = {
            type: 'MENU_STATE_CHANGE',
            state: {
                menuState: state
            }
        };
        menuStore.dispatch(obj);
    }

    static subscribeMenuState(state, cb) {
        MenuService.subscribeToAState(menuStore, state, cb, 'menuState')
    }

    static getMenuState() {
        return menuStore.getState()
    }

    static setMenuLocation(state) {
        if (MenuService.getMenuLocation() === state) {
            return;
        }
        MenuService.setState(locationStore, state, 'LOCATION_CHANGE')
    }

    static subscribeMenuLocation(cb) {
        return locationStore.subscribe(() => {
            cb()
        })
    }

    static getMenuLocation(state) {
        return locationStore.getState()
    }

}

export class NavigationService {
    static readyToNavidate(state) {
        navigateStore.dispatch({
                type: 'NAVIGATE',
                state: state

        })
    }

    static listenReadyToNavigate(cb) {
        navigateStore.subscribe(() => {
            cb()
        })
    }

    static getReadyToNavigate(cb) {
        return navigateStore.getState();
    }
}

export class BackButtonService {
    static set(state) {
        backButtonStore.dispatch({
            type: 'LOCATION_CHANGE',
            state: state
        })
    }
}
