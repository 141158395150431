import React, {Component} from 'react'
import './treediagram.scss'
import {Tree} from './'
export class TreeDiagram extends Component {
    render() {
        return (
            <div className={'tree-diagram'}>
                <Tree data={this.props.data}/>
            </div>
        )
    }
}
