import React, {Component} from 'react'
import './treediagram.scss'

export class Right extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset1: 0,
            offset2: 0,
            offset3: 0,
            offset4: 0
        }
    }


    boxRender(array) {
        return array
            ? <g>
                <g style={{transform: 'translateY(0px)'}}>
                    <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                        <tspan x="480.9219" y="33.519">{array.date}</tspan>
                    </text>
                    <g>
                        {array.result1 > array.result2 &&
                        <rect x="449" y="59" width="185" height="58" fill="#FF0000" fillOpacity="0.75"/>}
                        <foreignObject x="500" y="59" width="100" height="58">
                            <div className="text-wrapper"><p>{array.team1_text}</p></div>
                        </foreignObject>
                        <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                            <tspan x="605.441" y="94.019">{array.result1}</tspan>
                        </text>
                        <foreignObject x={13 + 449} y="74" width="28" height="28">
                            <img style={{
                                width: '100%'
                            }} src={"https://" + array.t1logo} alt=""/>
                        </foreignObject>
                    </g>
                    <g>
                        {array.result1 < array.result2 &&
                        <rect x="449" y="117" width="185" height="58" fill="#FF0000" fillOpacity="0.75"/>}
                        <foreignObject x="500" y="117" width="100" height="58">
                            <div className="text-wrapper"><p>{array.team2_text}</p></div>
                        </foreignObject>
                        <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                            <tspan x="604.626" y="152.019">{array.result2}</tspan>
                        </text>
                        <foreignObject x="461" y="132" width="28" height="28">
                            <img style={{
                                width: '100%'
                            }} src={"https://" + array.t2logo} alt=""/>
                        </foreignObject>
                    </g>
                    <rect x="450" y="59" width="185" height="116" stroke="white"/>
                    <line x1="450" y1="116.75" x2="636" y2="116.75" stroke="white" strokeOpacity="0.5"
                          strokeWidth="0.5"/>
                    <line x1="409" y1="116.75" x2="449" y2="116.75" stroke="white" strokeOpacity="0.75"
                          strokeWidth="0.5"/>
                </g>
            </g>
            : <g>
                <g style={{transform: 'translateY(0px)'}}>
                    <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                        <tspan x="480.9219" y="33.519"></tspan>
                    </text>
                    <g>
                        <foreignObject x="500" y="59" width="100" height="58">
                            <div className="text-wrapper"><p></p></div>
                        </foreignObject>
                        <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                            <tspan x="605.441" y="94.019"></tspan>
                        </text>
                    </g>
                    <g>
                        <foreignObject x="500" y="117" width="100" height="58">
                            <div className="text-wrapper"><p></p></div>
                        </foreignObject>
                        <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                            <tspan x="604.626" y="152.019"></tspan>
                        </text>
                    </g>
                    <rect x="450" y="59" width="185" height="116" stroke="white"/>
                    <line x1="450" y1="116.75" x2="636" y2="116.75" stroke="white" strokeOpacity="0.5"
                          strokeWidth="0.5"/>
                    <line x1="409" y1="116.75" x2="449" y2="116.75" stroke="white" strokeOpacity="0.75"
                          strokeWidth="0.5"/>
                </g>
            </g>;

    }

    render() {
        const data = this.props.data;
        return (
            <div>
                <svg width="638" height="693" viewBox="0 0 638 693" fill="none">
                    <g className={'quarter'} style={{transform: 'translateX(3px'}}>
                        <g style={{transform: 'translateY(0px)'}}>
                            <g>
                                <g style={{transform: 'translateY(0px)'}}>
                                    {this.boxRender(data[5])}
                                </g>
                            </g>
                            <g>
                                <g style={{transform: 'translateY(174px)'}}>
                                    {this.boxRender(data[6])}
                                </g>
                            </g>
                        </g>
                        <g style={{transform: 'translateY(343px)'}}>
                            <g>
                                <g style={{transform: 'translateY(0px)'}}>

                                    {this.boxRender(data[7])}
                                </g>
                            </g>
                            <g>
                                <g style={{transform: 'translateY(174px)'}}>

                                    {this.boxRender(data[8])}
                                </g>
                            </g>
                        </g>
                    </g>
                    <g style={{transform: 'translate(-222px, 88px)'}}>
                        <g>
                            <g>
                                {this.boxRender(data[11])}
                            </g>
                            <g style={{transform: 'translate(-0, 344px)'}}>
                                {this.boxRender(data[12])}
                            </g>
                        </g>
                    </g>
                    <g style={{
                        transform: 'translate(-222px, 162px)'
                    }}>
                        <g style={{transform: 'translate(-227px, 88px)'}}>
                            {this.boxRender(data[14])}
                        </g>
                    </g>
                    <line style={{transform: 'translate(186px, 0)'}} x1="226.75" y1="290" x2="226.75" y2="117"
                          stroke="white" strokeOpacity="0.75" strokeWidth="0.5"></line>
                    <line style={{transform: 'translate(186px, 344px)'}} x1="226.75" y1="290" x2="226.75" y2="117"
                          stroke="white" strokeOpacity="0.75" strokeWidth="0.5"></line>
                    <line style={{transform: 'translate(-262px, 0)'}} x1="448.75" y1="548" x2="448.75" y2="204"
                          stroke="white" strokeOpacity="0.75" strokeWidth="0.5"></line>
                </svg>
            </div>
        )
    }
}
