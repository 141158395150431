import {TreeDiagram} from '../tree/treediagram'
import React, {Component} from 'react'
import {api} from "../../../common";

export class BlTree extends Component {
    constructor(porps) {
        super(porps);
        this.state = {
            initialized: false
        };
        api('knockoutchart/bl', response => {
            this.data = response;
            this.setState({
                initialized: true
            })
        })
    }

    render() {
        return (
            <div className="bl-tree">
                {this.state.initialized && <TreeDiagram data={this.data}/>}
            </div>
        )
    }
}
