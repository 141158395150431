import React, {Component} from 'react'
//import {api} from './../../common'
import './quiz.scss'
import {MenuService, NavigationService} from "../../../actions";
import {api} from "../../../common";

export class Quiz extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            questions: null,
            stage: 'intro',
            answers: [],
            currentQuestionId: 0,
            currentQuestion: null,
            showTheCorrectAnswer: false,
            stageAnimationTime: 300,
            animateState: '',
            timeLimit: 30,
            timer: null,
            timeDelay: 500,
            summary: 0,
            showQuestion: 'preface',
            progressbar: new Array(10).fill({
                valid: null
            })
        }

        this.state = this.initialState;
    }

    componentWillMount() {
        //api('quiz')


        this.sub = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(function () {
                NavigationService.readyToNavidate(true);
            }, 300)
        })

        api('quiz', response => {
            this.setState({
                questions: response
            })
        })
    }

    componentWillUnmount() {
        this.sub();
    }

    animateStageOut(callback, time) {
        this.setState({
            animateState: 'out'
        });
        setTimeout(() => {
            this.setState({
                animateState: ''
            });
            callback()
        }, time)
    }

    navigate() {
        this.setState({
            animateState: 'out'
        });
        setTimeout(() => {
            MenuService.setMenuLocation('/games/')
        }, this.state.stageAnimationTime)
    }


    setStage(stageName, time, cb) {
        cb = cb || null;
        time = time || this.state.stageAnimationTime;
        this.animateStageOut(() => {
            this.setState({
                stage: stageName,
            });
            if (cb) {
                cb()
            }
        }, time);
    }

    startTimer(callback) {
        this.setState({timer: this.state.timeLimit, showQuestion: ''});
        this.timeout = setTimeout(() => {
            this.interval = setInterval(() => {
                if (this.state.timer > 0) {
                    this.setState({timer: this.state.timer - 1});
                } else {
                    this.validateAnswer('lie');
                }
            }, 1000);
        }, 1000)
    }

    startQuiz() {
        if (this.state.questions.length) {

        this.getQuestion();
        this.setStage('question', 300, () => {
            setTimeout(() => {
                this.startTimer();
            }, 600)
        })
        } else {
            this.setStage('no-internet', 300, () => {})
        }
    }

    endQuiz() {
        this.setStage('summary', 600)
    }

    getQuestion() {
        if (this.state.questions[this.state.currentQuestionId]) {
            this.setState({
                currentQuestion: this.state.questions[this.state.currentQuestionId],
            })
        }
    }

    validateAnswer(answer) {
        this.setState({
            preventClick: true
        });
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        console.log('incterval cleared');
        let stateUpdate = this.state.progressbar;

        if (answer === '1') {
            stateUpdate[this.state.currentQuestionId] = 'true';
            this.setState({
                summary: this.state.summary + 1
            })
        } else {
            stateUpdate[this.state.currentQuestionId] = 'lie'
        }

        if (this.state.showTheCorrectAnswer) {
            return false;
        }

        this.setState({
            showTheCorrectAnswer: true
        });

        setTimeout(() => {
            if (this.state.currentQuestionId < this.state.questions.length - 1) {
                this.nextQuestion();
            } else {
                this.endQuiz();
            }
        }, this.state.timeDelay);

    }

    nextQuestion() {
        this.setState({
            showQuestion: 'after-face'
        })
        setTimeout(() => {
            this.setState({
                currentQuestionId: this.state.currentQuestionId + 1,
                timer: this.state.timeLimit,
                showTheCorrectAnswer: false,
                showQuestion: 'preface'

            });
            this.getQuestion();
            setTimeout(() => {
                this.setState({
                    preventClick: false
                })
                this.startTimer()
            }, 600)
        }, 600)
    }

    processResult() {
        if (this.state.summary < 1) {
            return 'ez most nem sikerült! Legközelebb biztos jobb lesz az eredmény!';
        } else if (this.state.summary < 3) {
            return 'de még van hova fejlődni… Legközelebb biztos jobb lesz az eredmény!';
        } else if (this.state.summary < 5) {
            return 'de van még hova fejlődni! Legközelebb biztos jobb lesz az eredmény!';
        } else if (this.state.summary < 7) {
            return 'Nagyon sokat tudsz a csapatról, de még van mit tanulni!';
        } else if (this.state.summary < 9) {
            return 'A tudásod még nem tökéletes, de nagyon jó eredmény! ';
        } else {
            return 'Ez már valami! Te aztán vérbeli Veszprém drukker vagy! ';
        }
    }

    resetQuiz() {
        // api

        this.setStage('intro', 300, () =>  api('quiz', response => {
                this.setState({
                    questions: response,
                    answers: [],
                    currentQuestionId: 0,
                    currentQuestion: null,
                    stageAnimationTime: 300,
                    animateState: '',
                    timer: null,
                    preventClick: false,
                    showTheCorrectAnswer: false,
                    summary: 0,
                    progressbar: new Array(10).fill({
                        valid: null
                    })
                })
            })
        )}

    render() {
        return (
            <div className={"quiz " + (this.state.animateOut ? ' out' : '') + ' ' + this.state.showQuestion}>
                <div className="back-button" onClick={() => MenuService.setMenuLocation('/games/')}>
                    Vissza
                </div>
                <div className={"animate-wrapper " + (this.state.animateState ? this.state.animateState : '')}>

                    {/* intró */}
                    {this.state.stage === 'intro' &&
                    <div className="intro-container">
                        <div className="text-section">
                            <div className="title">Kvízmester</div>
                            <div className="text">
                                <strong>
                                    Te mennyire ismered a csapatot?
                                </strong>
                                <br/>
                                <br/>
                            </div>
                            <div className="text dotted">
                                <div className="number">1.</div>Válaszolj 10 kérdésre, és meglátod! <br/><br/>
                                <div className="number">2.</div>Válaszd ki az opciók közül az általad helyesnek vélt választ! <br/><br/>
                                <div className="number">3.</div>Figyelem! Minden kérdésre 30 másodperc áll rendelkezésedre! <br/><br/>

                            </div>
                        </div>
                        <div className="image-section">
                            <img src="/images/games/quiz/player-intro-2.png" alt=""/>
                            <img src="/images/games/quiz/player-intro.png" alt=""/>
                        </div>
                        <div className="start-the-game" onClick={() => this.startQuiz('question', 300)}>
                            Játszom
                        </div>
                    </div>}

                    {/* kérdések */}
                    {this.state.stage === 'question' &&
                    <div className={"question-container" + (this.state.preventClick ? ' prevent-click' : '')}>
                        <div className="question-wrapper">
                            <div className="question-title">
                                <div className="title-wrapper">
                                    {this.state.currentQuestion.question}
                                </div>
                            </div>
                            <div className="question-timer">
                                {this.state.timer}
                            </div>
                            <div className="answer-cards">
                                {
                                    this.state.currentQuestion.answers.map(choice =>

                                        <div
                                            className={'answer-card ' + ((this.state.showTheCorrectAnswer) && (choice.right_answer === '1' ? ' good-answer' : ' wrong-answer'))}
                                            onClick={() => {
                                                this.validateAnswer(choice.right_answer);
                                            }}

                                        >
                                            {console.log(this.state.showTheCorrectAnswer)}
                                            <div className="card-front">
                                                <svg width="433" height="483" viewBox="0 0 433 483" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g filter="url(#filter0_d)">
                                                        <path
                                                            d="M363 126.907C363 126.191 362.617 125.53 361.997 125.173L197.449 30.5732C196.831 30.2183 196.072 30.2184 195.454 30.5735L31.0027 125.173C30.3824 125.53 30 126.191 30 126.907V316.093C30 316.809 30.3824 317.47 31.0027 317.827L195.454 412.426C196.072 412.782 196.831 412.782 197.449 412.427L361.997 317.827C362.617 317.47 363 316.809 363 316.093V126.907Z"
                                                            fill="#DA0000" fill-opacity="1"/>
                                                        <path
                                                            d="M361.498 126.04C361.809 126.219 362 126.549 362 126.907V316.093C362 316.451 361.809 316.781 361.498 316.96L196.95 411.56C196.641 411.737 196.262 411.737 195.953 411.56L31.5014 316.96C31.1912 316.782 31 316.451 31 316.093V126.907C31 126.549 31.1912 126.218 31.5014 126.04L195.953 31.4403C196.262 31.2628 196.641 31.2627 196.95 31.4402L361.498 126.04Z"
                                                            stroke="url(#paint0_linear)" stroke-width="2"/>
                                                    </g>
                                                    <defs>
                                                        <filter id="filter0_d" x="0" y="0.307129" width="433"
                                                                height="482.386"
                                                                filterUnits="userSpaceOnUse"
                                                                colorInterpolationFilters="sRGB">
                                                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                            <feOffset dx="20" dy="20"/>
                                                            <feGaussianBlur stdDeviation="25"/>
                                                            <feColorMatrix type="matrix"
                                                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                                     result="effect1_dropShadow"/>
                                                            <feBlend mode="normal" in="SourceGraphic"
                                                                     in2="effect1_dropShadow"
                                                                     result="shape"/>
                                                        </filter>
                                                        <linearGradient id="paint0_linear" x1="279" y1="76" x2="109"
                                                                        y2="358"
                                                                        gradientUnits="userSpaceOnUse">
                                                            <stop offset="0.0110497" stopColor="#B70000"/>
                                                            <stop offset="0.508287" stopColor="#EA0000"/>
                                                            <stop offset="1" stopColor="#B70000"/>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                                <div className="answer">
                                                    {choice.answer}
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <svg width="433" height="483" viewBox="0 0 433 483" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g filter="url(#filter0_d)">
                                                        <path
                                                            d="M363 126.907C363 126.191 362.617 125.53 361.997 125.173L197.449 30.5732C196.831 30.2183 196.072 30.2184 195.454 30.5735L31.0027 125.173C30.3824 125.53 30 126.191 30 126.907V316.093C30 316.809 30.3824 317.47 31.0027 317.827L195.454 412.426C196.072 412.782 196.831 412.782 197.449 412.427L361.997 317.827C362.617 317.47 363 316.809 363 316.093V126.907Z"
                                                            fill= {choice.right_answer === '1' ? '#fff' : "#DA0000"} fill-opacity="1"/>
                                                        <path
                                                            d="M361.498 126.04C361.809 126.219 362 126.549 362 126.907V316.093C362 316.451 361.809 316.781 361.498 316.96L196.95 411.56C196.641 411.737 196.262 411.737 195.953 411.56L31.5014 316.96C31.1912 316.782 31 316.451 31 316.093V126.907C31 126.549 31.1912 126.218 31.5014 126.04L195.953 31.4403C196.262 31.2628 196.641 31.2627 196.95 31.4402L361.498 126.04Z"
                                                            stroke="url(#paint0_linear)" stroke-width="2"/>
                                                    </g>
                                                    <defs>
                                                        <filter id="filter0_d" x="0" y="0.307129" width="433"
                                                                height="482.386"
                                                                filterUnits="userSpaceOnUse"
                                                                colorInterpolationFilters="sRGB">
                                                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                            <feOffset dx="20" dy="20"/>
                                                            <feGaussianBlur stdDeviation="25"/>
                                                            <feColorMatrix type="matrix"
                                                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                                     result="effect1_dropShadow"/>
                                                            <feBlend mode="normal" in="SourceGraphic"
                                                                     in2="effect1_dropShadow"
                                                                     result="shape"/>
                                                        </filter>
                                                        <linearGradient id="paint0_linear" x1="279" y1="76" x2="109"
                                                                        y2="358"
                                                                        gradientUnits="userSpaceOnUse">
                                                            <stop offset="0.0110497" stopColor="#B70000"/>
                                                            <stop offset="0.508287" stopColor="#EA0000"/>
                                                            <stop offset="1" stopColor="#B70000"/>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                                <div className="answer">
                                                    {choice.answer}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="image-wrapper">
                            <img src="/images/games/quiz/player-questions.png" alt=""/>
                        </div>
                        <div className="progress-icons">
                            {
                                this.state.progressbar.map((progressState, key) => {
                                    if (progressState === 'true') {
                                        return (<div className="icon complete" key={key}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 8.42285L5.51305 11.9359L12 5.57715" stroke="white"
                                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>)
                                    } else if (progressState === 'lie') {
                                        return (
                                            <div className="icon complete">
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 12.8984L13 2.11584" stroke="white" stroke-width="2"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M2.2168 2L12.7678 12.7638" stroke="white" stroke-width="2"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={"icon " + (this.state.currentQuestionId === key ? 'active' : '')}>
                                                {key + 1}
                                            </div>
                                        )
                                    }
                                })
                            }

                        </div>
                    </div>}

                    {/* kiértékelés */}
                    {this.state.stage === 'summary' &&
                    <div className="summary-container">
                        <div className="images">
                            <img src="/images/games/quiz/left-player.png" alt="" className="left"/>
                            <img src="/images/games/quiz/right-player.png" alt="" className="right"/>
                        </div>

                        <div className="title">
                            {this.state.summary && this.state.summary > 6
                                ? 'Gratulálunk!'
                                : this.state.summary > 4
                                    ? 'Nem rossz,'
                                    :'Sajnáljuk,'

                            }
                        </div>
                        <div className="subtitle">
                            {this.processResult()}
                        </div>
                        <div className="result">
                            {this.state.summary * 10}%
                        </div>
                        <div className="button" style={{
                        marginTop: '45px'
                        }} onClick={() => this.resetQuiz()}>
                            újrajátszom
                        </div>
                        >
                        <div className="button" onClick={() => MenuService.setMenuLocation('/games/')}>
                            Vissza a többi játékhoz
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }
}
