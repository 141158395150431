import React, {Component} from 'react'
import './treediagram.scss'

export class Left extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset1: 0,
            offset2: 0,
            offset3: 0,
            offset4: 0
        }
    }

    boxRender(array) {
        return !array
            ? <g>
                <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                    <tspan x="31.9219" y="33.519"></tspan>
                </text>
                <g>

                    <foreignObject x="51" y="59" width="100" height="58">
                        <div className="text-wrapper"><p></p></div>
                    </foreignObject>
                    <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                        <tspan x="156.441" y="94.019"></tspan>
                    </text>
                </g>
                <g>

                    <foreignObject x="51" y="117" width="100" height="58">
                        <div className="text-wrapper"><p></p></div>
                    </foreignObject>
                    <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                        <tspan x="155.626" y="152.019"></tspan>
                    </text>

                </g>
                <rect x="1" y="59" width="185" height="116" stroke="white"/>
                <line x1="1" y1="116.75" x2="186" y2="116.75" stroke="white" strokeOpacity="0.5"
                      strokeWidth="0.5"/>
                <line x1="186" y1="116.75" x2="226" y2="116.75" stroke="white" strokeOpacity="0.75"
                      strokeWidth="0.5"/>
            </g>
            : <g>
                <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                    <tspan x="31.9219" y="33.519">{array.date}</tspan>
                </text>
                <g>
                    {array.result1 > array.result2 &&<rect x="1" y="59" width="185" height="58" fill="#FF0000" fillOpacity="0.75"/>}
                    <foreignObject x="51" y="59" width="100" height="58">
                        <div className="text-wrapper"><p>{array.team1_text}</p></div>
                    </foreignObject>
                    <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                        <tspan x="156.441" y="94.019">{array.result1}</tspan>
                    </text>
                    <foreignObject x="13" y="74" width="28" height="28">
                        <img style={{
                            width: '100%'
                        }} src={"https://" + array.t1logo} alt=""/>
                    </foreignObject>
                </g>
                <g>
                    {array.result1 < array.result2 && <rect y="117" width="185" height="58" fill="#FF0000" fillOpacity="0.75"/>}

                    <foreignObject x="51" y="117" width="100" height="58">
                        <div className="text-wrapper"><p>{array.team2_text}</p></div>
                    </foreignObject>
                    <text fill="white" fontSize="14" fontWeight="800" letterSpacing="0em">
                        <tspan x="155.626" y="152.019">{array.result2}</tspan>
                    </text>
                    <foreignObject x="12" y="132" width="28" height="28">
                        <img style={{
                            width: '100%'
                        }} src={"https://" + array.t2logo} alt=""/>
                    </foreignObject>
                </g>
                <rect x="1" y="59" width="185" height="116" stroke="white"/>
                <line x1="1" y1="116.75" x2="186" y2="116.75" stroke="white" strokeOpacity="0.5"
                      strokeWidth="0.5"/>
                <line x1="186" y1="116.75" x2="226" y2="116.75" stroke="white" strokeOpacity="0.75"
                      strokeWidth="0.5"/>
            </g>

    }

    render() {
        const data = this.props.data;
        return (
            <div>
                <svg width="638" height="693" viewBox="0 0 638 693" fill="none">
                    <g>
                        <g style={{transform: 'translateY(0px)'}}>
                            <g>
                                <g style={{transform: 'translateY(0px)'}}>
                                    {/*posi 1*/}
                                    {this.boxRender(data[1])}
                                </g>
                            </g>
                            <g>
                                <g style={{transform: 'translateY(174px)'}}>
                                    {/*posi 2*/}

                                    {this.boxRender(data[2])}
                                </g>
                            </g>
                        </g>
                        <g style={{transform: 'translateY(344px)'}}>
                            <g>
                                {/*posi3*/}
                                <g style={{transform: 'translateY(0px)'}}>
                                    {this.boxRender(data[3])}
                                </g>
                            </g>
                            <g>
                                {/*posi 4*/}
                                <g style={{transform: 'translateY(174px)'}}>
                                    {this.boxRender(data[4])}
                                </g>
                            </g>
                        </g>
                        <g style={{transform: 'translate(226px, 87px)'}}>
                            <g>
                                <g style={{transform: 'translateY(0px)'}}>
                                    {/*posi9*/}
                                    {this.boxRender(data[9])}
                                </g>
                            </g>
                            <g>
                                <g style={{transform: 'translateY(344px)'}}>
                                    {/*posi10  */}
                                    {this.boxRender(data[10])}
                                </g>
                            </g>
                        </g>
                        <g style={{transform: 'translate(451.5px, 252px)'}}>
                            <g>
                                <g style={{transform: 'translateY(0px)'}}>
                                    {/*posi13  */}
                                    {this.boxRender(data[13])}
                                </g>
                            </g>
                        </g>
                    </g>

                    <line x1="226.75" y1="290" x2="226.75" y2="117" stroke="white" strokeOpacity="0.75"
                          strokeWidth="0.5"/>
                    <line x1="187" y1="289.75" x2="227" y2="289.75" stroke="white" strokeOpacity="0.75"
                          strokeWidth="0.5"/>
                    <line x1="452" y1="548" x2="452" y2="204" stroke="white" strokeOpacity="0.75"
                          strokeWidth="0.5"/>

                    <line x1="226.75" y1="634" x2="226.75" y2="461" stroke="white" strokeOpacity="0.75"
                          strokeWidth="0.5"/>
                    <line x1="186" y1="460.75" x2="226" y2="460.75" stroke="white" strokeOpacity="0.75"
                          strokeWidth="0.5"/>
                    <line x1="2" y1="633.75" x2="187" y2="633.75" stroke="white" strokeOpacity="0.5" strokeWidth="0.5"/>
                    <defs>
                        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern3" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern4" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern5" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern6" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern7" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern8" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern9" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern10" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern11" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern12" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <pattern id="pattern13" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use href="#image0" transform="scale(0.000833333)"/>
                        </pattern>
                        <image id="image0" width="1200" height="1200" href="/images/telekom_logo.svg"/>
                    </defs>
                </svg>

            </div>
        )
    }
}

