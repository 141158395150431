import React, {Component} from 'react';
import Swiper from '@eredessil/react-id-swiper';
import 'swiper/dist/css/swiper.min.css'
import {MenuService, NavigationService} from "../../actions";
import './telekom.scss';
import {api} from "../../common";

export class Telekom extends Component {

    static mockData = {};

    constructor(props) {
        super(props);
        this.state = {
            newsList: [],
            activeGallery: null,
            animateInProgress: false,
            modalContent: null,
            modalState: null,
            activeModalImage: null,
            activeModalVideo: null
        }
    }

    componentDidMount() {
        api('news', response => {
            this.setState({
                newsList: response
            })

            // todo: hack
            window.dispatchEvent(new Event('resize'));
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 0)
        });
        this.sub = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(() => {
                NavigationService.readyToNavidate(true);
            }, 300)
        })
    }

    componentWillUnmount() {
        this.sub()
    }

    animationEnd(delay) {
        delay = delay || 300;
        setTimeout(() => {
            this.setState({
                animateInProgress: false
            })
        }, delay)
    }

    setActiveNews(id) {
        console.log(id);
        if (!this.state.animateInProgress && this.state.galleryId !== id) {
            // start animation
            this.setState({animateInProgress: true});
            if (!this.state.activeGallery) {
                // set animate type
                this.setState({
                    animateInProgress: true,
                    activeGallery: this.state.newsList[id],
                    galleryId: id,
                    animateType: 'show-gallery'
                });
                this.animationEnd(150)
            } else if (id !== 'hide-gallery') {
                this.setState({
                    animateType: 'switch-gallery',
                });
                setTimeout(() => {
                    this.setState({
                        activeGallery: this.state.newsList[id],
                        animateType: 'show-gallery',
                        galleryId: id,
                    });

                }, 150);
                this.animationEnd(150);
            } else {
                this.setState({
                    animateType: null,
                    galleryId: null,
                    activeGallery: null
                });
                this.animationEnd();
            }
        }
    }

    constructAnimationClasses() {
        let animState = this.state.animateInProgress ? ' animating' : '';
        let animType = this.state.animateType ? ' ' + this.state.animateType : '';
        let galleryIsVisible = this.state.animateType ? ' gallery-is-visible' : '';
        return galleryIsVisible + animState + animType;
    }

    openModal(params) {
        this.setState({
            modalContent: params,
            activeModalImage: params.type === 'image' ? params.url : null,
            activeModalVideo: params.type === 'video' ? params.url : null
        })
    }

    closeModal() {

        this.setState({
            modalState: 'out',
        })
        setTimeout(() => {
            this.setState({
                modalState: null,
                modalContent: null
            })
        }, 300)
    }

    render() {
        const params = {
            direction: 'vertical',
            slidesPerView: 'auto',
            freeMode: true,
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            mousewheel: true,
        };

        return (
            <div className={"telekom-screen" + (this.state.animateOut ? ' out' : '')}>
                <div className="back-button" onClick={() =>
                    this.state.activeGallery
                        ? this.setActiveNews('hide-gallery')
                        : MenuService.setMenuLocation('/')}
                >
                    Vissza
                </div>
                <div
                    className={"left-content" + this.constructAnimationClasses()}>
                    <div className="bg-shape">
                        <svg width="1075" height="1080" viewBox="0 0 1075 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1075 -77.0946C1075 -77.8095 1074.62 -78.47 1074 -78.8271L266.763 -544.424C266.145 -544.78 265.383 -544.78 264.764 -544.423L-542 -78.8272C-542.619 -78.47 -543 -77.8096 -543 -77.095V854.095C-543 854.81 -542.619 855.47 -542 855.827L264.764 1321.42C265.383 1321.78 266.145 1321.78 266.763 1321.42L1074 855.827C1074.62 855.47 1075 854.81 1075 854.095V-77.0946Z" fill="#E20074"/>
                        </svg>
                    </div>
                    <div className={"inner-content"}>
                        <div className="top">
                            <div className="title">A Telekom aktivitásai</div>
                            <div className="subtitle">A Telekom a Veszprém kézilabdacsapat névadó szponzoraként a
                                pénzügyi hozzájárulás mellett telekommunikációs szolgáltatásokkal és eszközökkel segíti
                                a sikeres felkészülést.
                            </div>
                            <div className="text">
                                A csapat támogatásán kívül arra is kiemelt figyelmet fordít,
                                hogy még teljesebbé tegye a szurkolás élményét! Ehhez olyan aktivitásokat biztosít,
                                amelyek a 21. század legmodernebb technológiájával hozzák közelebb a csapatot a
                                szurkolókhoz. A Telekom folyamatosan megújuló aktivitásaival találkozhatsz a meccseken,
                                de az Arénán kívül szervezett eseményeken, valamint online, a közösségi csatornákon is
                                kihagyhatatlan élmények, érdekes háttérinformációk és értékes nyeremények
                                várnak. <br/><br/>
                            </div>
                        </div>
                    </div>
                    {this.state.activeGallery &&
                    <div className={"detail-content"}>
                        <div className="title">{this.state.activeGallery.title}</div>
                        <div className="active-card-wrapper">
                            <div className="image-section">
                                <div className="image-wrapper">
                                    <img src={'https://' + this.state.activeGallery.image} alt=""/>
                                </div>
                            </div>
                            <div className="text-section">
                                <div className="lead">
                                    {this.state.activeGallery.news_content}
                                </div>
                            </div>
                        </div>
                        <div className="gallery-wrapper">
                            {this.state.activeGallery.images.length > 0 &&
                            <div className="gallery-wrapper-inner">
                                <div className="subtitle">Képek</div>
                                {this.state.activeGallery.images.map((image, key) =>


                                    !this.state.activeGallery.videos.length || (this.state.activeGallery.videos.length && key < 4)
                                        ? <div className="thumbnail" onClick={() => this.openModal({
                                            type: 'image',
                                            url: 'https://' + image.original})}>
                                            <img src={'https://' + image.thumb} alt=""/>
                                        </div> : '')}
                            </div>}
                            {this.state.activeGallery.videos.length > 0 &&
                            <div className="gallery-wrapper-inner">
                                <div className="subtitle">Videók</div>
                                {this.state.activeGallery.videos.map((video, key) =>
                                    <div className="thumbnail" onClick={() => this.openModal({
                                        type: 'video',
                                        contentId: video
                                    })}>
                                        <img src={"/images/telekom/video-thumb.png"} alt=""/>
                                    </div>
                                )}
                            </div>
                            }
                        </div>
                    </div>}
                    <div className="bottom">
                        <img src="/images/telekom/t-logo-large.svg" alt=""/>
                    </div>
                </div>
                <div className="right-content">
                    <div className="telekom-slider">
                        <Swiper {...params}>
                            {this.state.newsList.map((news, key) =>
                                <div key={key} className="telekom-activity-card"
                                     onClick={() => this.setActiveNews(key)}>
                                    <div className="image-section">
                                        <div className="image-wrapper">
                                            <img src={"https://" + news.image} alt=""/>
                                        </div>
                                        <div className="caption-wrapper">
                                            <div className="icon-wrapper">
                                                {!!news.images.length && <div className="icon">
                                                    <img src="/images/telekom/image.svg" alt=""/>
                                                </div>}
                                                {!!news.videos.length && <div className="icon">
                                                    <img src="/images/telekom/video.svg" alt=""/>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-section">
                                        <div className='title'>{news.title}</div>
                                        <div className='lead'>{news.news_content}</div>
                                    </div>
                                </div>
                            )}
                        </Swiper>
                    </div>
                </div>
                {this.state.modalContent &&
                <div className={"modal" + (this.state.modalState ? ' ' + this.state.modalState : '')}>
                    <div className="modal-backdrop" onClick={() => {
                        this.closeModal()
                    }}/>
                    <div className="modal-content">
                        <div className="close-icon" onClick={() => {
                            this.closeModal()
                        }}>
                            <img src={'/images/telekom/X.png'} alt=""/>
                        </div>
                        {this.state.modalContent.type === 'image' && <img src={this.state.activeModalImage} alt=""/>}
                        {this.state.modalContent.type === 'video' &&
                        <iframe src={"https://player.vimeo.com/video/" + this.state.modalContent.contentId} title={'veszprem-gallery'} width="1280"
                                height="720" frameBorder="0"
                                webkitallowfullscreen mozallowfullscreen allowFullScreen autoPlay></iframe>}
                    </div>
                </div>
                }
            </div>
        )
    }
}
