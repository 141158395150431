import React, {Component} from 'react'
import "./BottomAngle.scss"
export class BottomAngle extends Component {
    render() {
        return (
            <div className="bottom-angle">
                <div className="angle-flack">
                    <div className="angle-flack-text">
                        <div className="angle-flack-text-title">
                            {this.props.title}
                        </div>
                        <div className="angle-flack-text-paragraph italic" dangerouslySetInnerHTML={{__html: this.props.content}}>

                        </div>
                    </div>
                    <svg width="1773" height="218" viewBox="0 0 1773 218" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M2347 0H1168.16L0 672V2016L1168.16 2688L2337 2016L2347 0Z" fill="#E20074"/>
                    </svg>
                </div>
            </div>
        )
    }
}