import React, {Component} from 'react';
import {MenuService, NavigationService} from "../../../actions";
import {BLTabel, BLMatches, BlTree} from "./";
import '../championship.scss';


export class BL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollbar: false,
            activeTab: 'tabel',
            animationClass: '',
        };
    }

    componentDidMount() {
        this.sub = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(() => {
                NavigationService.readyToNavidate(true);
            }, 1000)
        })
    }

    componentWillUnmount() {
        this.sub()
    }

    navigate(tabName) {
        this.setState({
            animationClass: 'out'
        })
        setTimeout(() => {
            this.setState({
                activeTab: tabName,
                animationClass: 'in'
            })

            setTimeout(() => {
                this.setState({
                    animationClass: ''
                })
            }, 300)
        }, 300)
    }

    renderTab() {
        if (this.state.activeTab === 'tabel') {
        }

        switch (this.state.activeTab) {
            case 'tabel':
                return <BLTabel/>;
            case 'matches':
                return <BLMatches/>;
            case 'tree':
                return <BlTree/>;
            default:
                return false;

        }
    }

    render() {
        return (
            <div className={'bl' + (this.state.animateOut ? ' out' : '')}>
                <div className="back-button" onClick={() => MenuService.setMenuLocation('/championship')}>
                    vissza
                </div>
                <div className="team-menu">
                    <div className="menu-button-wrapper">
                        <div className="menu-button" onClick={() => this.navigate('tabel')}>
                            BL tabellák
                        </div>
                    </div>
                    <div className="menu-button-wrapper">
                        <div className="menu-button"
                             onClick={() => this.navigate('matches')}>
                            BL meccsnaptár
                        </div>
                    </div>
                    <div className="menu-button-wrapper">
                        <div className="menu-button"
                             onClick={() => this.navigate('tree')}>
                            BL ágrajz
                        </div>
                    </div>
                </div>
                <div className={"tab-container " + this.state.animationClass}>
                    {this.renderTab()}
                </div>
                <div className="telekom-logo">
                    <img src="/images/telekom_logo.svg" alt=""/>
                </div>
            </div>
        )
    }
}
