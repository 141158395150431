import React, {Component} from 'react';
import {DrawTeamTable} from '../../../component/tabella';
import '../championship.scss';
import {api} from "../../../common";

export class BLTabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollbar: false,
            activeTab: 'NB1Tabel tabella'
        };

        this.nb1 = Array(7);
        this.nb1.fill({
            team: '????',
            playOff: '?',
            playedMatch: '?',
            wins: '?',
            draw: '?',
            loose: '?',
            goalRate: '?',
            pointsRate: '?',
            form: '?',
        });

        this.nb1 = [
            {
                highlighted: true,
                team: '????',
                playOff: '?',
                playedMatch: '?',
                wins: '?',
                draw: '?',
                loose: '?',
                goalRate: '?',
                pointsRate: '?',
                form: '?',
            },
            ...this.nb1
        ]


        this.data = Array(4);
        this.data.fill({
            head: {
                team: 'A csoport'
            },
            body: this.nb1
        })
    }

    componentWillMount() {
        api('leaugetables/bl', response => {
            console.log(response)
            this.setState({
                response: response
            })
        }
        )
    }

    render() {
        return (
            <div className={'tabella-bl' + (this.state.animateOut ? ' out' : '')}>
                {this.state.response &&
                    <DrawTeamTable
                        data={this.state.response}
                        scrollbar={true}
                    />
                }
            </div>
        )
    }
}
