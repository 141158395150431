import React, {Component} from "react";

export class AnimatedFadeRight extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.setState({
            time: this.props.time ||300,
            count: this.props.count || 0,
            delay: this.props.delay || 200
        })
    }

    render() {
        return (
            <div style={{
                opacity: '0',
                animation: 'fade-in-left '+ this.state.time + 'ms forwards ease-out',
                animationDelay: (this.state.delay * this.state.count) + 'ms'
            }}>
                {this.props.children}
            </div>
        )
    }
}

export class AnimatedScaleIn extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.setState({
            time: this.props.time || 300,
            count: this.props.count || 0,
            delay: this.props.delay || 0,
            transformOrigin: this.props.transformOrigin || 'center center'
        })
    }

    render() {
        return (
            <div style={{
                opacity: '0',
                animation: 'fade-in-scale '+ this.state.time + 'ms forwards ease-out',
                animationDelay: (this.state.delay * this.state.count) + 'ms',
                transformOrigin: this.state.transformOrigin
            }}>
                {this.props.children}
            </div>
        )
    }
}
