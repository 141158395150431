import {TreeDiagram} from '../tree/treediagram'
import React, {Component} from 'react'
import {MenuService, NavigationService} from "../../../actions";
import {api} from "../../../common";

export class HungarianTournamentTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false
        }
    }
    componentDidMount() {
        api('knockoutchart/mb', response => {
            this.data = response;
            this.setState({
                initialized: true
            })
        })
        //this.swiper.goTo(1);
        this.subscribe = MenuService.subscribeMenuLocation(() => {
            this.setState({
                animateOut: true
            });
            setTimeout(() => {
                NavigationService.readyToNavidate(true);
            }, 300)
        })
    }

    componentWillUnmount() {
        this.setState(null);
        this.subscribe();
    }

    render() {
        return (
            <div className="bl-tree">
                {this.state.initialized && <TreeDiagram data={this.data}/>}
            </div>
        )
    }
}