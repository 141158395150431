import {Component} from "react";
import React from "react";
import {AnimatedFadeRight, AnimatedScaleIn} from "../../component";

export class PlayerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: null,
            activeTab: 'stats',
            player: this.props.player,
            tabs: this.filterTabs(),
            cb: null,
            nextType: '',
            prevType: 's'
        }
    }

    componentWillMount() {
        console.log(this.state.player)
    }

    filterTabs() {
        let array = this.props.tabs;
        let tabs = [];
        this.props.player.menus.map((item) => tabs.push(...array.filter(tab => tab.type === item)));
        return tabs;
    }

    switchTab(type) {
        this.setState({
            beforeChange: 'out',
            nextTab: type,
            prevType: this.state.type
        });
        setTimeout(() => {
            this.setState({
                activeTab: type,
                beforeChange: 'in',
            });
            setTimeout(() => {
                this.setState({
                    beforeChange: null
                })
            }, 150)
        }, 150)
    }

    nameComponent(nicName, realName) {
        return (
            <div className="player-name">
                {this.props.legendary && (
                    <div className="stars">
                        <img src="/images/players/stars.svg" alt=""/>
                    </div>
                )}
                <div className="nicname">{nicName}</div>
                <div className="realname">{realName}</div>
            </div>
        )
    }

    tabStats(tab, key) {
        return (
            <div key={key} className="tab">
                <div className="left-content">
                    {this.nameComponent('#' + this.props.player.jersey + ' ' + this.props.player.last_name, this.props.player.first_name)}
                    <div className="title">
                        <div className="position">{this.state.player.position}</div>&nbsp;/&nbsp;<div className="team">Telekom veszprém</div>
                    </div>
                    <div className="details">
                        <AnimatedFadeRight count={0} time={300}>
                            <div className="detail-row">
                                <div className="name">Állampolgárság:</div>
                                <div className="value"> {' ' + this.state.player.nationality}</div>
                            </div>
                        </AnimatedFadeRight>
                        <AnimatedFadeRight count={1} time={300}>
                            <div className="detail-row">
                                <div className="name">Születési idő:</div>
                                <div className="value"> {this.state.player.birthdate}</div>
                            </div>
                        </AnimatedFadeRight>
                        <AnimatedFadeRight count={2} time={300}>
                            <div className="detail-row">
                                <div className="name">Csillagjegy:</div>
                                <div className="value">{this.state.player.zodiac}</div>
                            </div>
                        </AnimatedFadeRight>
                    </div>
                    <div className="statistics">
                        <AnimatedScaleIn delay='200' count={0} time={300}>
                            <div className="height">
                                <StatisticShape delay={'0'} percent={this.state.player.height / 207 * 100}/>
                                <div className="value"><NumberAnimation delay={'0'} number={this.state.player.height}/>
                                </div>
                                <div className="name"><strong>Magasság</strong> [cm]</div>
                            </div>
                        </AnimatedScaleIn>
                        <AnimatedScaleIn delay='200' count={1} time={300}>
                            <div className="weight">
                                <StatisticShape delay={'200'} percent={this.state.player.weight / 125 * 100}/>
                                <div className="value"><NumberAnimation delay={'300'}
                                                                        number={this.state.player.weight}/></div>
                                <div className="name"><strong>Súly</strong> [kg]</div>
                            </div>
                        </AnimatedScaleIn>
                        <AnimatedScaleIn delay='200' count={2} time={300}>
                            <div className="other">
                                <StatisticShape delay={'600'} percent={this.state.player.national_team / 150 * 100}/>
                                <div className="value"><NumberAnimation delay={'600'} number={this.state.player.national_team || 0}/></div>
                                <div className="name"><strong>Válogatottság</strong></div>
                            </div>
                        </AnimatedScaleIn>
                    </div>
                </div>
                {this.props.legendary || <div className="speedradar">
                    <div className="speed"><NumberAnimation number={this.props.player.speedradar || 0}/></div>
                </div>}
            </div>
        )
    }

    tabHistory(tab, key) {
        return (
            <div key={key} className="tab">
                <div className="history-conteiner">
                    <div className="left-content">
                        {this.nameComponent('#' + this.props.player.jersey + ' ' + this.props.player.last_name, this.props.player.first_name)}
                        <div className="history">
                            {this.state.player.clubs.map((team, key) => {
                                return (
                                    <AnimatedFadeRight count={key} time={600}>
                                        <div className="detail-row">
                                            <div className="title">
                                                <div className="date">{team.date}</div>
                                                <div className="value"><strong>{team.name} /</strong> {team.country}
                                                </div>
                                            </div>
                                            <div className="timeline">
                                                <svg width="658" height="19" viewBox="0 0 658 19" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M656.488 5.05062C656.804 5.22732 657 5.5613 657 5.92362V13.0764C657 13.4387 656.804 13.7727 656.488 13.9494L649.986 17.5819C649.682 17.7513 649.313 17.7512 649.01 17.5818L642.512 13.9496C642.196 13.7729 642 13.4389 642 13.0767V5.92329C642 5.56105 642.196 5.22715 642.512 5.0504L649.01 1.41822C649.313 1.24877 649.682 1.24872 649.986 1.41809L656.488 5.05062Z"
                                                        stroke={this.props.legendary ? '#E0AD71' : '#FF0000'}
                                                        strokeWidth="2"/>
                                                    <path
                                                        d="M15.4877 5.05062C15.804 5.22732 16 5.5613 16 5.92362V13.0764C16 13.4387 15.804 13.7727 15.4877 13.9494L8.98552 17.5819C8.68234 17.7513 8.313 17.7512 8.00988 17.5818L1.51207 13.9496C1.19589 13.7729 1 13.4389 1 13.0767V5.92329C1 5.56105 1.19589 5.22715 1.51207 5.0504L8.00987 1.41822C8.31301 1.24877 8.68234 1.24872 8.98551 1.41809L15.4877 5.05062Z"
                                                        stroke={this.props.legendary ? '#E0AD71' : '#FF0000'}
                                                        strokeWidth="2"/>
                                                    <line x1="17" y1="9" x2="641" y2="9.00005"
                                                          stroke={this.props.legendary ? '#E0AD71' : '#FF0000'}
                                                          strokeWidth="2"/>
                                                </svg>

                                            </div>
                                        </div>
                                    </AnimatedFadeRight>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    tabHandsize(tab, key) {
        return (
            <div key={key} className={'tab ' + tab.type}>
                <div className="handsize-container">
                    <div className="hand-size">
                        <AnimatedScaleIn transformOrigin={'400px center'}>
                            <div className={'hand'} >
                                <img src={"https://"  + this.state.player.palm} alt=""/>
                            </div>
                        </AnimatedScaleIn>
                    </div>
                </div>
                <div className="angle-flack">
                    <div className="angle-flack-text">
                        <div className="angle-flack-text-title">
                            Nagyobb a tenyered?
                        </div>
                        <div className="angle-flack-text-paragraph italic">
                            Mérd össze a tenyered a játékoséval...
                        </div>
                    </div>
                    <svg width="1773" height="218" viewBox="0 0 1773 218" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M2347 0H1168.16L0 672V2016L1168.16 2688L2337 2016L2347 0Z" fill="#E20074"/>
                    </svg>
                </div>
            </div>
        );
    }

    tabDetails(tab, key) {
        return (
            <div key={key} className={'tab ' + tab.type}>
                <div className="left-content">
                    {this.nameComponent('#' + this.props.player.jersey + ' ' + this.props.player.last_name, this.props.player.first_name)}
                    <div className="slogen">
                        {!!this.state.player.motto && this.state.player.motto}
                    </div>
                    <div className="details-container">
                        <div className="collapse-details">
                            {this.state.player.funfacts && this.state.player.funfacts.map((fact, key) =>
                                <AnimatedFadeRight time={300} count={0} key={key}>
                                    <div className="div" onClick={() => {
                                        this.setState({activeCollapseKey: key})
                                        console.log(this.state.activeCollapseKey, key)
                                    }}>
                                        <CollapseRow open={this.state.activeCollapseKey === key}
                                                     title={fact.question} icon={'icon'} content={fact.answer}/>
                                    </div>
                                </AnimatedFadeRight>)}
                        </div>
                    </div>
                </div>
                <div className="angle-flack">
                    <div className="fleck-wrapper">
                        <div className="fanfacts">
                            {this.state.player.favourites && this.state.player.favourites.map((favorit, key) =>
                                key < 3
                                    ? <div className="fanfact" key={key}>
                                        <img src={'https://' + favorit.image} alt=""/>
                                        <strong>{favorit.type}</strong>
                                        <span>{favorit.content}</span>
                                    </div>
                                    : null
                            )}
                        </div>
                    </div>
                    <svg width="1773" height="218" viewBox="0 0 1773 218" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M2347 0H1168.16L0 672V2016L1168.16 2688L2337 2016L2347 0Z" fill="#E20074"/>
                    </svg>
                </div>
            </div>)
    }

    render() {
        return (
            <div className={'player-details' + (this.props.legendary ? ' legendary' : '')}>
                <div className="details-wrapper">
                    <img src={'https://' + this.state.player.image2 || '/images/players/player-details.png'} alt=""
                         className="player-image"
                         style={{
                             WebkitMaskImage: 'url(' + ('https://' + this.state.player.image2 || '/images/players/player-details.png') + ')'
                         }}
                    />
                    <div className="player-menu">
                        {this.state.tabs.map((tab, key) =>
                            <p onClick={() => this.switchTab(tab.type)} key={key}
                               className={'tab-button' + (this.state.activeTab === tab.type ? ' active' : '')}>{tab.name}</p>
                        )}
                    </div>
                    <div className={'tabs' + (
                        this.state.beforeChange ? ' ' + this.state.beforeChange : '') + ' ' + (this.nextType)}>
                        {this.state.tabs.map((tab, key) => {
                                if (tab.type === this.state.activeTab) {
                                    if (tab.type === 'stats') {
                                        return <div key={key}>{this.tabStats(tab, key)}</div>;
                                    } else if (tab.type === 'infos') {
                                        return <div key={key}>{this.tabDetails(tab, key)}</div>;
                                    } else if (tab.type === 'hand-size') {
                                        return <div key={key}>{this.tabHandsize(tab, key)}</div>;
                                    } else if (tab.type === 'history') {
                                        return <div key={key}>{this.tabHistory(tab, key)}</div>
                                    }
                                    return false;
                                }
                                return false;
                            }
                        )}
                    </div>
                </div>
            </div>
        )
    }

}


class CollapseRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open
        }
    }

    componentWillMount() {
        this.setState({
            open: this.props.open
        })
    }

    componentWillReceiveProps(props) {
        //alert('asdf')
        this.setState({
            open: props.open
        })
    }

    render() {
        return (
            <div className="collapse-body">
                <div className="collapse-head">
                    <div className="icon">
                        <QuestionIcon active={this.state.open}/>
                    </div>
                    <div className={"title"} dangerouslySetInnerHTML={{__html: this.props.title}}/>
                </div>
                <div className={"collapse-content" + (this.state.open ? ' open' : '')}
                     dangerouslySetInnerHTML={{__html: this.props.content}}/>
            </div>
        )
    }
}

class NumberAnimation extends Component {
    constructor(params) {
        super(params);
        this.state = {
            number: 0,
            framerate: 60,
            delay: 0
        }
    }

    componentWillMount() {
        this.setState({
            delay: this.props.delay
        })
        setTimeout(() => {
            this.interval = setInterval(() => {
                if (this.state.number >= parseInt(this.props.number)) {
                    clearInterval(this.interval)
                    this.setState({
                        number: parseInt(this.props.number)
                    })
                } else {
                    this.setState({
                        number: this.state.number + parseInt(this.props.number) / (1000 / 8)
                    });
                }

            }, 1000 / this.state.framerate)
        }, this.state.delay)
    }


    componentWillUnmount() {
        clearInterval(this.interval)
    }


    render() {
        return (
            <span>{Math.floor(this.state.number)}</span>
        )
    }
}

class QuestionIcon extends Component {
    render() {
        return (
            <div>
                <svg className={'collapse-icon'} width="52" height="60" viewBox="0 0 52 60" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill={this.props.active ? '#FF0000' : 'transparent'} strokeWidth={'2'}
                          stroke={this.props.active ? 'transparent"' : '#fff'}
                          d="M52 15.9823C52 15.2637 51.6145 14.6003 50.9902 14.2446L26.9829 0.564408C26.3689 0.214558 25.6159 0.214652 25.0021 0.564656L1.00938 14.2445C0.385296 14.6003 0 15.2635 0 15.9819V43.2981C0 44.0165 0.385296 44.6797 1.00938 45.0355L25.0021 58.7153C25.6159 59.0653 26.3689 59.0654 26.9829 58.7156L50.9902 45.0354C51.6145 44.6797 52 44.0163 52 43.2978V15.9823Z"/>
                    <path className={'path' + (this.props.active ? ' inactive' : '')}
                          d="M20.8935 28.4C21.4535 28.4 21.9068 28.5733 22.2535 28.92C22.6135 29.2667 22.7935 29.7133 22.7935 30.26C22.7935 30.82 22.6135 31.28 22.2535 31.64C21.9068 31.9867 21.4535 32.16 20.8935 32.16C20.3468 32.16 19.8935 31.9867 19.5335 31.64C19.1868 31.28 19.0135 30.82 19.0135 30.26C19.0135 29.7133 19.1868 29.2667 19.5335 28.92C19.8935 28.5733 20.3468 28.4 20.8935 28.4ZM25.9912 28.4C26.5512 28.4 27.0045 28.5733 27.3512 28.92C27.7112 29.2667 27.8912 29.7133 27.8912 30.26C27.8912 30.82 27.7112 31.28 27.3512 31.64C27.0045 31.9867 26.5512 32.16 25.9912 32.16C25.4445 32.16 24.9912 31.9867 24.6312 31.64C24.2845 31.28 24.1112 30.82 24.1112 30.26C24.1112 29.7133 24.2845 29.2667 24.6312 28.92C24.9912 28.5733 25.4445 28.4 25.9912 28.4ZM31.0888 28.4C31.6488 28.4 32.1022 28.5733 32.4488 28.92C32.8088 29.2667 32.9888 29.7133 32.9888 30.26C32.9888 30.82 32.8088 31.28 32.4488 31.64C32.1022 31.9867 31.6488 32.16 31.0888 32.16C30.5422 32.16 30.0888 31.9867 29.7288 31.64C29.3822 31.28 29.2088 30.82 29.2088 30.26C29.2088 29.7133 29.3822 29.2667 29.7288 28.92C30.0888 28.5733 30.5422 28.4 31.0888 28.4Z"
                          fill="white"/>
                    <path className={'path' + (!this.props.active ? ' inactive' : '')}
                          d="M21.5567 27.72C21.5701 26.1867 21.9967 24.9933 22.8367 24.14C23.6767 23.2867 24.8434 22.86 26.3367 22.86C27.7501 22.86 28.8501 23.1733 29.6367 23.8C30.4234 24.4133 30.8167 25.2867 30.8167 26.42C30.8167 27.1267 30.6567 27.7133 30.3367 28.18C30.0167 28.6333 29.5501 29.1333 28.9367 29.68C28.4167 30.1467 28.0301 30.5533 27.7767 30.9C27.5234 31.2333 27.3967 31.6333 27.3967 32.1H24.3967C24.3967 31.3267 24.5367 30.7133 24.8167 30.26C25.0967 29.7933 25.4901 29.32 25.9967 28.84C26.3567 28.4933 26.6167 28.2 26.7767 27.96C26.9501 27.7067 27.0367 27.4133 27.0367 27.08C27.0367 26.72 26.9501 26.46 26.7767 26.3C26.6034 26.1267 26.3301 26.04 25.9567 26.04C25.5301 26.04 25.2101 26.18 24.9967 26.46C24.7834 26.7267 24.6701 27.1467 24.6567 27.72H21.5567ZM25.8167 33.4C26.3767 33.4 26.8301 33.5733 27.1767 33.92C27.5367 34.2667 27.7167 34.7133 27.7167 35.26C27.7167 35.82 27.5367 36.28 27.1767 36.64C26.8301 36.9867 26.3767 37.16 25.8167 37.16C25.2701 37.16 24.8167 36.9867 24.4567 36.64C24.1101 36.28 23.9367 35.82 23.9367 35.26C23.9367 34.7133 24.1101 34.2667 24.4567 33.92C24.8167 33.5733 25.2701 33.4 25.8167 33.4Z"
                          fill="white"/>
                </svg>
            </div>
        )
    }
}

class StatisticShape extends Component {
    constructor(props) {
        super(props);
        this.state = {
            percent: 720,
            delay: 0
        }
    }

    componentDidMount() {
        this.setState({
            delay: this.props.delay
        })
        setTimeout(() => {
            this.setState({
                percent: (720 - parseInt(this.props.percent) * 7.2)
            })
        }, this.state.delay)
    }

    render() {
        return (
            <div className={'info-shape-wrapper'}>
                <svg width="208" height="239" viewBox="0 0 208 239" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{strokeDashoffset: this.state.percent}} className={'info-shape-indicator'}
                          d="M104.717 1.00585L206.747 59.607C207.213 59.8745 207.5 60.3706 207.5 60.9077V178.092C207.5 178.629 207.213 179.126 206.747 179.393L104.717 237.994C104.254 238.26 103.685 238.26 103.223 237.994L1.2526 179.393C0.787034 179.126 0.5 178.63 0.5 178.093V60.9074C0.5 60.3704 0.787035 59.8744 1.2526 59.6068L103.223 1.00604C103.685 0.740155 104.254 0.740083 104.717 1.00585Z"
                          stroke="white" strokeOpacity="0.4"/>
                    <path className={'info-shape'}
                          d="M104.717 1.00585L206.747 59.607C207.213 59.8745 207.5 60.3706 207.5 60.9077V178.092C207.5 178.629 207.213 179.126 206.747 179.393L104.717 237.994C104.254 238.26 103.685 238.26 103.223 237.994L1.2526 179.393C0.787034 179.126 0.5 178.63 0.5 178.093V60.9074C0.5 60.3704 0.787035 59.8744 1.2526 59.6068L103.223 1.00604C103.685 0.740155 104.254 0.740083 104.717 1.00585Z"
                          stroke="white" strokeOpacity="0.4" strokeWidth={2}/>
                </svg>
            </div>
        )
    }
}
